import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Tabs, Tab, Container } from "@mui/material";
import { fetchAllJobPostings } from "../../../../api/employer/jobPosts";
import {
  fetchAllIndustries,
  fetchAllIndustriesById,
} from "../../../../api/employer/jobPosts";
import CustomSnackbar from "../../../../components/ResusableComponents/CustomSnackbar";
import SkeletonTable from "../../../../components/Tables/SkeletonTable";
import EmployerJobCard from "../EmployerJobCard";
import AdminDrawerList from "../../../../components/Drawer/AdminDrawerList";

const JobPostings = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [jobPosted, setJobPosted] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);
  const [industries, setIndustries] = useState([]); // Store fetched industries
  const [activeTab, setActiveTab] = useState("All"); // Default tab

  const handleErrorMessage = (error) => {
    const message = error.response?.message || "Failed to fetch data.";
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    // Fetch industries for dynamic tabs
    const fetchIndustries = async () => {
      try {
        const response = await fetchAllIndustries();
        if (response) {
          // Extract categories and their organization counts
          const allCategories = response.reduce((acc, resItem) => {
            resItem.items.forEach((item) => {
              if (item.name) {
                acc.push({
                  name: item.name,
                  count: item.organizationCount || 0, // Include the count
                });
              }
            });
            return acc;
          }, []);

          // Map categories to the structure required for tabs
          const industryTabs = allCategories.map((category) => ({
            name: category.name,
            type: category.name, // Use the name as the identifier
            count: category.count,
          }));

          setIndustries(industryTabs);
        }
      } catch (error) {
        console.error("Error fetching industries:", error);
      }
    };

    fetchIndustries();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        let organizationData;

        if (activeTab === "All") {
          // Call fetchAllJobPostings for the "All" tab
          organizationData = await fetchAllJobPostings();
        } else {
          // Call fetchAllIndustriesById for specific industry tabs
          organizationData = await fetchAllIndustriesById(activeTab);
        }

        setJobPosted(organizationData);
        setSnackbarSeverity("success");
      } catch (error) {
        handleErrorMessage(error);
        setSnackbarSeverity("error");
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [activeTab]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <AdminDrawerList>
      <Box mt={2}>
        {/* Dynamic Tabs */}
        <Container maxWidth="xl" sx={{ mb: 4 }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            textColor="secondary"
            indicatorColor="white"
            variant="scrollable" // Enables horizontal scrolling
            scrollButtons="auto" // Show scroll buttons when needed
            sx={{
              "& .MuiTab-root": {
                fontWeight: 600,
                textTransform: "none",
                borderRadius: "8px",
                bgcolor: "#f5f5f5",
                px: 12, // Add padding inside the tabs for better aesthetics
                "&:not(:last-child)": {
                  mr: 0, // Remove margin/gap between tabs
                },
                "&.Mui-selected": {
                  bgcolor: "green",
                  color: "white",
                },
              },
              "& .MuiTabs-scrollButtons": {
                "&.Mui-disabled": {
                  opacity: 0.3, // Style for disabled scroll buttons
                },
              },
            }}
          >
            <Tab value="All" label="All" />
            {industries.map((industry) => (
              <Tab
                key={industry.type}
                value={industry.type}
                label={`${industry.name}`} // Display name and count
              />
            ))}
          </Tabs>
        </Container>
      </Box>

      {/* Job Listings */}
      {loading ? (
        <SkeletonTable />
      ) : jobPosted?.length > 0 ? (
        <Grid container spacing={3}>
          {jobPosted.map((jobData, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <EmployerJobCard
                jobData={jobData}
                organizationId={jobData?.organizationId}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          sx={{ mt: 4, fontFamily: "Poppins, sans-serif" }}
        >
          No jobs available.
        </Typography>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </AdminDrawerList>
  );
};

export default JobPostings;
