// src/utils/tokenUtils.js
import { jwtDecode } from "jwt-decode";

export const decodeToken = (token) => {
  try {
    return jwtDecode(token); // Updated to call jwtDecode directly
  } catch (error) {
    console.error("Error decoding access token:", error);
    return null;
  }
};
