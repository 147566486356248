import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchAllQuotationsData = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/quotation/allQuotes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the new response data to match the required fields
        const result = response.data.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            convertedToSubscription:item?.convertedToSubscription,
            organizationName: item?.client?.organizationName,
            organizationAddress: item?.client?.organizationAddress,
            contactNumber: item?.client?.contactNumber,
            email: item?.client?.email,
            cvAccess: item.cvAccess,
            pricePerCVAccess: item.pricePerCVAccess,
            tcCVAccess: item.tcCVAccess,
            cvDownloadAccess: item.cvDownloadAccess,
            pricePerCVDownload: item.pricePerCVDownload,
            tcCVDownload: item.tcCVDownload,
            invites: item.invites,
            pricePerInvite: item.pricePerInvite,
            tcInvites: item.tcInvites,
            jobPostAccess: item.jobPostAccess,
            pricePerJobPost: item.pricePerJobPost,
            tcJobPostAccess: item.tcJobPostAccess,
            grossAmount: item.grossAmount,
            gst: item.gst,
            totalCost: item.totalCost,
            createdAt: item.createdAt,
        }));

        return result;

    } catch (error) {
        console.error('Error fetching Subscription details:', error);
        throw error;
    }
};

export const fetchByQuotationId = async (quoteId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/quotation/${quoteId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            return null;
        }

        // Check for HTTP errors
        if (!response.success) {
            throw new Error(response.message || "Failed to fetch Subscription details");
        }

        // Return the data directly
        return response.data;
    } catch (error) {
        console.error("Error fetching Subscription details:", error);
        throw error;
    }
};


export const createQuotation = async (payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/quotation/create_Quote`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        if (response.message === 'Quotation created successfully') {
            return response.data; // Return relevant data
        } else {
            throw new Error(`Failed to add Subscription: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during subscription creation:', error);

        // Add response-specific error handling if needed
        if (error.response) {
            console.error('Error response details:', {
                status: error.response.status,
                message: error.response.statusText,
                body: await error.response.text(),
            });
        }

        throw error; // Propagate error to the calling function
    }
};

export const updateQuotation = async (quoteId, payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/quotation/${quoteId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', // Set header for JSON
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        // Check if the response contains a success message
        if (response.message === 'Quotation updated successfully') {
            return response.data; // Assuming response.organization contains the newly created organization
        } else {
            throw new Error(`Failed to update Organization: ${response.message || 'Unknown error'}`);
        }

    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate error to be handled elsewhere
    }
};

export const deleteQuotation = async (quoteId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/quotation/${quoteId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        // If the response is a success, return the message directly

        if (response.message === 'Quotation deleted successfully') {

            return response; // Return success response
        } else {
            throw new Error(`Failed to delete role: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};

export const convertToSubscribe = async (quoteId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/quotation/convert_subscription/${quoteId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            return null;
        }

        // Check for HTTP errors
        if (!response.success) {
            throw new Error(response.message || "Failed to fetch Subscription details");
        }

        // Return the data directly
        return response;
    } catch (error) {
        console.error("Error fetching Subscription details:", error);
        throw error;
    }
};