import React from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Button, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const EmployerJobCard = ({ jobData, organizationId }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/employer/employerJobDetails", {
      state: { jobId: jobData?._id, organizationId: organizationId },
    });
  };

  const companyName = jobData?.organizationId?.organizationName;
  const companyLogo = jobData?.organizationId?.companyLogo?.url;


  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: "480px", // Fixed height for consistent sizing
        flexDirection: "column",
        justifyContent: "space-between",
        boxShadow: "none",
        borderRadius: 2,
        padding: "1.5rem",
        textAlign: "left",
      }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
          <img
            src={companyLogo}
            alt="Logo"
            style={{ width: 50, marginRight: 15 }}
          />
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                color: "#a1a4b5",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {companyName}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: "#6A1B9A",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {jobData?.jobTitle}
            </Typography>
          </Box>
        </Box>

        <Typography
          variant="body1"
          sx={{
            color: "#51ce8a",
            marginBottom: 2,
            fontSize: "1.35rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
          }}
        >
          ₹{jobData?.ctc}
        </Typography>

        <Typography
          sx={{
            color: "#a1a4b5",
            marginBottom: 2,
            fontWeight: 400,
            lineHeight: 1.5,
            fontSize: "13px",
            fontFamily: "Poppins, sans-serif",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3, // Limit to 3 lines
          }}
        >
          {jobData?.jobRole}
        </Typography>
      </Box>
      <Box sx={{ marginBottom: 3 }}>
        <video
          controls
          style={{
            width: "100%",
            height: "200px",
            borderRadius: "12px",
            border: "2px solid #ddd",
          }}
        >
          <source src={jobData?.jobVideoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={handleNavigate}
          sx={{
            width: "48%",
            fontSize: "12px",
            padding: "8px 0",
            background: "#51ce8a",
            "&:hover": { background: "#45b678" },
          }}
        >
          View Applications
        </Button>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon sx={{ color: "#777", fontSize: "16px" }} />
          <Typography
            variant="body2"
            sx={{
              color: "#777",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {jobData?.jobLocation}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default EmployerJobCard;
