import React from 'react';
import { Paper, Typography, Button, LinearProgress, Box } from '@mui/material';
import Vacanzi from '../../assets/vacanzi.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const JobCard = ({ handleNavigate,jobPosted }) => {

    return (
        <Paper elevation={0} sx={{
            width: 'auto', bgcolor: 'background.paper',
            boxShadow: 'none', borderRadius: 2, padding: '1.5rem', flex: '1px 1px auto',
        }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginBottom: 2 }}>
                <img src={Vacanzi} alt="Logo" style={{ width: 50, marginRight: 15 }} />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <Typography variant="body2" sx={{ fontWeight: 600, color: '#a1a4b5 !important', fontFamily: 'Poppins, sans-serif' }}>
                        Vacanzi Pvt.
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: '600 !important', color: '#6A1B9A', textAlign: 'left' }}>
                        Project Manager
                    </Typography>
                </Box>
            </Box>

            {/* Salary Range */}
            <Typography variant="body1" sx={{
                color: '#51ce8a !important', marginBottom: '20px !important', fontSize: '1.35rem', fontFamily: 'Poppins, sans-serif', // Properly applying the font family
                fontWeight: 400,
                lineHeight: 1.2
            }}>
                ₹1,200 - ₹1,800
            </Typography>

            {/* Job Description */}
            <p
                style={{
                    color: '#a1a4b5', // Slightly adjusted hex code for readability
                    marginBottom: '2rem',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    fontStyle: 'normal',
                    fontSize: '13px',
                    textAlign: 'justify', // Justifies the text
                    fontFamily: 'Poppins, sans-serif' // Properly applying the font family
                }}
            >
                It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
            </p>
            {/* Progress Bar */}
            <Typography
                variant="body2"
                sx={{
                    marginBottom: 1,
                    color: '#777', // Faded text color
                    fontSize: '16px', // Equivalent to 'fs-16'
                    fontWeight: 600, // Equivalent to 'fw-600'
                    paddingBottom: '5px' // Equivalent to 'pb-15'
                }}
            >
                Opening Jobs (15/20)
            </Typography>

            <LinearProgress
                variant="determinate"
                value={75}
                sx={{
                    marginBottom: 4,
                    color: 'red', // This will change the progress bar color
                    padding: '4px',
                    borderRadius: '1rem',
                    '& .MuiLinearProgress-bar': {
                        backgroundColor: 'red', // Set the color of the bar itself
                    },
                    '& .MuiLinearProgress-root': {
                        backgroundColor: 'transparent', // Optional: Set the track (background) color, if desired
                    }
                }}
            />

            {/* Buttons and Location Section */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNavigate}
                    sx={{
                        width: '48%',
                        fontSize: '12px',
                        padding: '8px 0',
                        background: '#51ce8a',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Adding shadow to match the "waves-effect"
                        '&:hover': {
                            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.2)' // Optional: For hover effect similar to "waves-effect"
                        }
                    }}
                >
                    View Applications
                </Button>

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <LocationOnIcon sx={{ color: '#777', fontSize: '16px', }} />
                    <Typography variant="body2" sx={{ color: '#777', fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins, sans-serif' }}>
                        Mysore
                    </Typography>
                </Box>

            </Box>
        </Paper>
    );
};

export default JobCard;
