import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchRolesData = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }
    try {
        const response = await fetchWithAuth(`${baseUrl}/admin/getadmins`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // If response is OK, process it
        const result = response.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            _id: item._id,
            email: item.email,
            name: item.fullName,
            phoneNumber: item.phoneNumber,
            role: item.role,
            createdAt: new Date(item.createdAt).toLocaleString('en-GB', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric'
            }),
        }));
        return result;

    } catch (error) {
        console.error('Error fetching roles data:', error);
        throw error;
    }
};

export const createRole = async (payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/admin/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload),
        });

        if (!response) {
            return; // Handle logout and redirect behavior if response is null (token invalidated)
        }

        // Check if the response contains a success message
        if (response.message === 'Admin created successfully') {
            // Return the admin data or any success response data you want
            return response.admin; // Assuming response.admin contains the newly created admin object
        } else {
            throw new Error(`Failed to add role: ${response.message || 'Unknown error'}`);
        }

    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate error to be handled elsewhere
    }
};

export const updateRole = async (roleId, payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/admin/${roleId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(payload),
        });

        if (response.message === 'Admin updated successfully') {
            // Return the admin data or any success response data you want
            return response.admin; // Assuming response.admin contains the newly created admin object
        } else {
            throw new Error(`Failed to add role: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error updating role:', error);
        throw error;
    }
};

export const deleteRole = async (roleId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/admin/${roleId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        // If the response is a success, return the message directly

        if (response.message === 'Admin deleted successfully') {

            return response; // Return success response
        } else {
            throw new Error(`Failed to delete role: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};

export const resetPassword = async ({ adminId, newPassword, secretKey }) => {
    const url = secretKey
        ? `${baseUrl}/admin/resetSAPassword`
        : `${baseUrl}/admin/resetPassword`;

    const requestBody = secretKey
        ? { adminId, newPassword, secretKey }
        : { adminId, newPassword };

    try {
        const response = await fetchWithAuth(url, {
            method: 'POST',
            body: JSON.stringify(requestBody),
        });

        // Assuming response is a success message
        return response;
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error; // Rethrow error to handle in the caller
    }
};
