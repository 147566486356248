import React from 'react';
import { Box } from '@mui/material';
import ChatList from '../../../components/Chats/ChatList';
import ChatDetails from '../../../components/Chats/ChatDetails';

const EmployerChat = ({ users, selectedChat, handleChatClick, chatMessages, handleSend, handleInputChange, messageData, supportOrg, handleStatusChange, statusMessage }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                margin: 2,
            }}
        >
            {/* Chat List Column */}
            <Box sx={{ flex: 8, height: '80vh' }}>
                <ChatList users={users} onChatClick={handleChatClick} />
            </Box>

            {/* Chat Details Column */}
            <Box sx={{ flex: 20, height: '80vh' }}>
                {selectedChat ? (
                    <ChatDetails chatMessages={chatMessages} supportOrg={supportOrg} handleInputChange={handleInputChange} handleSend={handleSend} messageData={messageData} handleStatusChange={handleStatusChange} statusMessage={statusMessage} />
                ) : (
                    <div>Select a chat to view messages</div>
                )}
            </Box>
        </Box>
    );
};

export default EmployerChat;
