import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';
import { fetchBySubscriptionId } from '../../../api/subscriptions/subscriptions';
import CustomSnackbar from '../../../components/ResusableComponents/CustomSnackbar';
import { Paper, Typography, Box, Divider } from '@mui/material';

const AllSubscriptionDetails = () => {
    const { id } = useParams();
    const [subscriptionDetails, setSubscriptionDetails] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            if (!id) return;

            try {
                const response = await fetchBySubscriptionId(id);
                setSubscriptionDetails(response);
            } catch (error) {
                console.error('Error fetching subscription details:', error);
                setSnackbarOpen(true);
                setSnackbarSeverity("error");
                setSnackbarMessage('Failed to load subscription details');
            }
        };

        fetchSubscriptionDetails();
    }, [id]);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <AdminDrawerList>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <Box sx={{ p: 2 }}>
                <Paper elevation={0} sx={{ p: 3, border: '1px solid #ddd', borderRadius: '8px' }}>
                    <Typography variant="h6" gutterBottom>
                        Subscription Details
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {subscriptionDetails ? (
                        <Box>
                            <Typography variant="body1" gutterBottom>
                                <strong>Tier:</strong> {subscriptionDetails.tier}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Job Posts:</strong> {subscriptionDetails.jobPosts}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>User Access:</strong> {subscriptionDetails.userAccess}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>CV Downloads:</strong> {subscriptionDetails.cvDownloads}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Invites:</strong> {subscriptionDetails.invites}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Subscription Fee:</strong> ₹{subscriptionDetails.subscriptionFee}
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                <strong>Created At:</strong> {new Date(subscriptionDetails.createdAt).toLocaleString()}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="body1" color="textSecondary">
                            Loading subscription details...
                        </Typography>
                    )}
                </Paper>
            </Box>
        </AdminDrawerList>
    );
};

export default AllSubscriptionDetails;
