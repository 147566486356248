import React from 'react';
import SchoolIcon from '@mui/icons-material/School';
import { Typography, Box } from '@mui/material';

const JobSeekerEducation = ({ educationDetails }) => {
  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h6"
        sx={{ fontWeight: 'bold', marginBottom: 2, display: 'flex', alignItems: 'center' }}
      >
        <SchoolIcon sx={{ mr: 2 }} />
        Education
      </Typography>
      <ul style={{ paddingInlineStart: '20px', margin: 0 }}>
        {educationDetails?.length > 0 ? (
          educationDetails.map((item, index) => (
            <li key={index} style={{ marginBottom: '16px', listStyleType: 'disc' }}>
              <Box sx={{ paddingLeft: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {item.degree || 'N/A'}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {item.institution || 'N/A'} |{' '}
                  {item.startDate && item.endDate
                    ? `${new Date(item.startDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                      })} - ${new Date(item.endDate).toLocaleDateString('en-US', {
                        year: 'numeric',
                      })}`
                    : 'N/A'}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: '#a1a4b5', marginTop: 0.5, textAlign: 'justify', display: 'block' }}
                >
                  Field of Study: {item.fieldOfStudy || 'N/A'}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ color: 'text.secondary', display: 'block', marginTop: '8px' }}
                >
                  Grade: {item.grade || 'N/A'}
                </Typography>
              </Box>
            </li>
          ))
        ) : (
          <Typography variant="body2" sx={{ color: 'text.secondary', paddingLeft: 1 }}>
            No education details available.
          </Typography>
        )}
      </ul>
    </Box>
  );
};

export default JobSeekerEducation;
