import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchEmployerJobsPostedById = async (orgId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetchWithAuth(`${baseUrl}/organization/jobposts/${orgId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
        );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response) {
            throw new Error("Failed to fetch Organization details");
        }
        if (response.message === 'Job posts retrieved successfully') {

            // Parse and return JSON response
            return response.jobPosts;
        }
        else {
            throw new Error(`Failed to fetch job posts: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error("Error fetching organization details:", error);
        throw error; // Propagate error for further handling
    }
};
export const fetchEmployerJobPostedDetailsById = async (jobId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetchWithAuth(`${baseUrl}/organization/job/${jobId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
        );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response) {
            throw new Error("Failed to fetch Organization details");
        }
        if (response) {
            // Parse and return JSON response
            return response.job;
        }

        else {
            throw new Error(`Failed to fetch job posts: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error("Error fetching organization details:", error);
        throw error; // Propagate error for further handling
    }
};
export const fetchEmployerJobStatDetailsById = async (jobId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetchWithAuth(`${baseUrl}organization/job/${jobId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
        );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response) {
            throw new Error("Failed to fetch Organization details");
        }
        if (response.message === 'Job posts retrieved successfully') {

            // Parse and return JSON response
            return response.jobPosts;
        }
        else {
            throw new Error(`Failed to fetch job posts: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error("Error fetching organization details:", error);
        throw error; // Propagate error for further handling
    }
};