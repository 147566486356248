import React from "react";
import VideoSlider from "../../ResusableComponents/VideoSlider";

const JobVideoCards = ({ videodata }) => {

  const media = Array.isArray(videodata)
    ? Array.from(
      new Set(
        videodata
          .filter(video => video.jobVideoUrl) // Remove null or undefined URLs
          .map(video => video.jobVideoUrl) // Get only unique URLs
      )
    ).map(src => ({
      type: "video",
      src,
    }))
    : [];

  return <VideoSlider media={media} />;
};

export default JobVideoCards;
