import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    MenuItem,
    Select,
    FormControl,
    Button,
} from '@mui/material';

const EmployerForm = ({ onSubmit, initialValues }) => {
    const [formData, setFormData] = useState({
        organizationName: '',
        email: '',
        contactNumber: '',
        orgType: '',
        organizationAddress: '',
        adminFullName: '',
        adminPhone: '',
        adminEmail: '',
        adminPassword: '',
    });

    // useEffect to update formData when initialValues change
    useEffect(() => {
        if (initialValues) {
            setFormData({
                organizationName: initialValues.organizationName || "",
                email: initialValues.email || "",
                contactNumber: initialValues.contactNumber || "", // Note: You had `emailID` in the AddCateres, not `email`
                orgType: initialValues.orgType || "",
                organizationAddress: initialValues.organizationAddress || "",
                adminFullName: initialValues.adminFullName || "",
                adminPhone: initialValues.adminPhone || "",
                adminEmail: initialValues.adminEmail || "",
                adminPassword: initialValues.adminPassword || "",

            });
        }
    }, [initialValues]); // This will re-run when initialValues change

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        onSubmit(formData);
    };


    return (
        <>
            <Typography variant="h5" fontWeight="bolder" mb={3} gutterBottom>
                {initialValues ? 'Edit' : 'Add'} Employer
            </Typography>
            <form onSubmit={handleFormSubmit}>
                <Grid container spacing={2}>
                    {/* Organization Name */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Organization Name</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Organization Name"
                            value={formData.organizationName}
                            onChange={handleChange}
                            name="organizationName"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>

                    {/* Email */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Email</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Email Address"
                            value={formData.email}
                            onChange={handleChange}
                            name="email"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>

                    {/* Contact Number */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Contact Number</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Contact Number"
                            value={formData.contactNumber}
                            onChange={handleChange}
                            name="contactNumber"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>

                    {/* Organization Type */}
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        >
                            <Typography component="legend">Organization Type</Typography>
                            <Select value={formData.orgType} name="orgType" onChange={handleChange} displayEmpty>
                                <MenuItem value="" disabled>
                                    Select Organisation Type
                                </MenuItem>
                                <MenuItem value="company">Company</MenuItem>
                                <MenuItem value="MNC">MNC</MenuItem>
                                <MenuItem value="consultant">Consultant</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Organization Address */}
                    <Grid item xs={12}>
                        <Typography component="legend">Address</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Organization Address"
                            value={formData.organizationAddress}
                            onChange={handleChange}
                            name="organizationAddress"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>

                    {/* Admin Full Name */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Admin FullName</Typography>
                        <TextField
                            fullWidth
                            placeholder="Admin FullName"
                            value={formData.adminFullName}
                            onChange={handleChange}
                            name="adminFullName"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Admin Email</Typography>
                        <TextField
                            fullWidth
                            placeholder="Admin Email"
                            value={formData.adminEmail}
                            onChange={handleChange}
                            name="adminEmail"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Admin Phone</Typography>
                        <TextField
                            fullWidth
                            placeholder="Admin Phone"
                            value={formData.adminPhone}
                            onChange={handleChange}
                            name="adminPhone"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Admin Password</Typography>
                        <TextField
                            fullWidth
                            placeholder="Admin Password"
                            value={formData.adminPassword}
                            onChange={handleChange}
                            name="adminPassword"
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            color="success"
                            type="submit"
                        >
                            {initialValues ? 'Update' : 'Add'} Employer                       
                             </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default EmployerForm;
