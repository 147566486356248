import React, { useState } from 'react';
import { Grid, TextField, Box, Typography, Button, FormControl, Select, MenuItem } from '@mui/material';

const SourcingTracker = ({ handleSubmit }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    gender:'',
    appliedPosition: '',
    preferredLocation: '',
    currentCompany: '',
    currentDesignation: '',
    ctc: '',
    noticePeriod: '',
    experience: '',
    experienceType: '',
    industry: '',
  });

  // Validation function to check if all fields are filled
  const isFormValid = () => {
    return Object.values(formData).every((value) => value.trim() !== '');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      handleSubmit(formData); // Pass form data to the parent handler
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom mb={4}>
        Sourcing Tracker
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          {/* Full Name */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Full Name</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Full Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* Email */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Email</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* Phone */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Phone</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Phone"
              name="phone"
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Gender</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Gender"
              name="gender"
              type="text"
              value={formData.gender}
              onChange={handleChange}
              required
            />
          </Grid>
          {/* Applied Position */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Applied Position</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Applied Position"
              name="appliedPosition"
              value={formData.appliedPosition}
              onChange={handleChange}
            />
          </Grid>
          {/* Preferred Location */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Preferred Location</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Preferred Location"
              name="preferredLocation"
              value={formData.preferredLocation}
              onChange={handleChange}
            />
          </Grid>
          {/* Current Company */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Current Company</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Current Company"
              name="currentCompany"
              value={formData.currentCompany}
              onChange={handleChange}
            />
          </Grid>
          {/* Current Designation */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Current Designation</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Current Designation"
              name="currentDesignation"
              value={formData.currentDesignation}
              onChange={handleChange}
            />
          </Grid>
          {/* Total Experience */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Total Experience</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Total Experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
            />
          </Grid>
          {/* Current CTC */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Current CTC</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Current CTC"
              name="ctc"
              value={formData.ctc}
              onChange={handleChange}
            />
          </Grid>
          {/* Notice Period */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Notice Period</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Notice Period"
              name="noticePeriod"
              value={formData.noticePeriod}
              onChange={handleChange}
            />
          </Grid>
          {/* Experience Type */}
          <Grid item xs={12} sm={6}>
            <Typography fontWeight="bold">Experience Type</Typography>
            <TextField
              size="small"
              fullWidth
              placeholder="Enter Experience Type"
              name="experienceType"
              value={formData.experienceType}
              onChange={handleChange}
            />
          </Grid>
          {/* Industry */}
          <Grid item xs={12} sm={6}>
            <FormControl size="small" fullWidth>
              <Typography fontWeight="bold">Industry</Typography>
              <Select
                labelId="industry-type-label"
                id="industry-type-select"
                name="industry"
                value={formData.industry}
                onChange={handleChange}
              >
                <MenuItem value="IT">IT</MenuItem>
                <MenuItem value="BFSI">BFSI</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* Submit Button */}
        <Grid item xs={12} sm={6} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            type="submit"
            disabled={!isFormValid()} // Disable button if form is not valid
          >
            <Typography sx={{ textTransform: 'none' }} variant="body2">
              Upload Candidate
            </Typography>
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default SourcingTracker;
