import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { fetchEmployerJobsPostedById } from '../../../api/employer/employerjobs';
import CustomSnackbar from '../../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../../components/Tables/SkeletonTable';
import EmployerJobCard from './EmployerJobCard';

const EmployerJobPostedTab = ({ organizationId }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [jobPosted, setJobPosted] = useState(null);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);

  const handleErrorMessage = (error) => {
    const message = error.response?.message || "Failed to fetch data.";
    setSnackbarMessage(message)
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const organizationData = await fetchEmployerJobsPostedById(organizationId);
        setJobPosted(organizationData);
        setSnackbarSeverity("success");
      } catch (error) {
        handleErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [organizationId]);

  return (
    <Box mt={2}>
      {loading ? (
        <SkeletonTable />
      ) : jobPosted?.length > 0 ? (
        <Grid container spacing={3}>
          {jobPosted.map((jobData, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <EmployerJobCard jobData={jobData} organizationId={organizationId} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography
          variant="h6"
          align="center"
          color="textSecondary"
          sx={{ mt: 4, fontFamily: 'Poppins, sans-serif' }}
        >
          No jobs available.
        </Typography>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default EmployerJobPostedTab;
