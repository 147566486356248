import React, { useState, useEffect } from 'react';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';
import { fetchJobSeekerSupport, fetchJobSeekerSupportById, jobSeekerSendMessage, jobSeekerUpdateChatStatus } from '../../../api/support/support';
import CustomSnackbar from '../../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../../components/Tables/SkeletonTable';
import JobSeekerChat from './JobSeekerChat';

const JobSeekerSupport = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [supportUser, setSupportUser] = useState();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [chatMessages, setChatMessages] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [messageData, setMessageData] = useState('');
    const [statusMessage, setStatusMessage] = useState('Open');

    const handleChatClick = async (chat) => {
        setSelectedChat(chat._id); // Update the selected chat ID
        try {
            const messages = await fetchJobSeekerSupportById(chat._id); // Fetch the messages for the clicked chat
            console.log("Fetched Messages:", messages); // Log the fetched messages
            setChatMessages(messages?.messages); // Update state with the fetched messages
            setSupportUser(messages?.supportTicket?.user);
            setStatusMessage(messages?.supportTicket?.status);
        } catch (error) {
            console.error("Error fetching chat messages:", error);
            handleErrorMessage(error); // Handle the error (optional, based on your setup)
        }
    };

    // Fetch the employer support data on component mount
    useEffect(() => {
        const getData = async () => {
            try {
                const supportData = await fetchJobSeekerSupport();
                setData(supportData);
                if (supportData.length > 0) {
                    setSelectedChat(supportData[0]._id); // Select the first chat by default
                }
                setSnackbarMessage("Employer Support Fetched Successfully");
                setSnackbarSeverity("success");
            } catch (error) {
                handleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    // Handle error messages
    const handleErrorMessage = (error) => {
        const message = error.response?.data?.message || error.message || "An unknown error occurred";
        setSnackbarMessage(message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
    };

    // Close snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleInputChange = (event) => {
        setMessageData(event.target.value);
    };
    const handleSend = async () => {
        if (!messageData.trim()) return;  // Avoid sending empty messages

        const payload = { messageContent: messageData };  // Create the payload
        try {
            const response = await jobSeekerSendMessage(payload, selectedChat);  // Call sendMessage API
            console.log('Message sent:', response);  // Log the sent message response
            setChatMessages([...chatMessages, response]);  // Update the chat messages with the new message
            setMessageData('');  // Clear the input field after sending
            setSnackbarMessage("Message sent successfully");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);  // Show snackbar for success
        } catch (error) {
            handleErrorMessage(error);  // Handle error if message sending fails
        }
    };


    const handleStatusChange = async (status) => {
        const payload = { status };
        try {
            const response = await jobSeekerUpdateChatStatus(payload, selectedChat);
            console.log('Status changed:', response);
            setStatusMessage(response?.status);
            setSnackbarMessage(`Status updated to ${status}`);
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
        } catch (error) {
            handleErrorMessage(error);
        }
    };


    return (
        <AdminDrawerList>
            {loading ? (
                <SkeletonTable />
            ) : (
                <JobSeekerChat
                    users={data}
                    selectedChat={selectedChat}
                    handleChatClick={handleChatClick}
                    chatMessages={chatMessages}  // Pass chatMessages to EmployerChat
                    handleSend={handleSend}
                    handleInputChange={handleInputChange}
                    messageData={messageData}
                    supportUser={supportUser}
                    handleStatusChange={handleStatusChange}
                    statusMessage={statusMessage}
                />
            )}
            <CustomSnackbar
                open={snackbarOpen}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleSnackbarClose}
            />
        </AdminDrawerList>
    );
};

export default JobSeekerSupport;
