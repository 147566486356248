import React from 'react';
import { Paper, Typography, Button, Box, Avatar, Divider, Stack } from '@mui/material';
import Vacanzi from '../../assets/vacanzi.png'; // Replace with your company logo

const JobSeekerSubscriptionCard = ({ handleNavigate, subscriptionData }) => {
  return (
    <Paper
      elevation={6}
      sx={{
        width: '100%',
        maxWidth: 450,
        mx: 'auto',
        height: 600, // Fixed height for consistent card size
        p: 3,
        borderRadius: 4,
        bgcolor: '#ffffff',
        boxShadow: '0 8px 24px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensures content is spaced appropriately
        gap: 2,
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Avatar
          src={subscriptionData?.profileImage || Vacanzi}
          alt="Profile Image"
          sx={{ width: 80, height: 80, border: '3px solid #6A1B9A' }}
        />

        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: '#333',
            textAlign: 'center',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {subscriptionData?.fullName || 'N/A'}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: '#555',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {subscriptionData?.email || 'Email not provided'}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#555',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          {subscriptionData?.phone || 'Phone not provided'}
        </Typography>
      </Box>

      <Divider sx={{ width: '100%' }} />

      {/* Subscription Info Section */}
      <Stack spacing={2} sx={{ width: '100%', flex: 1, overflowY: 'auto' }}>
        {subscriptionData?.subscriptions?.map((subData, index) => (
          <Box
            key={index}
            sx={{
              p: 2,
              bgcolor: '#f9f9f9',
              borderRadius: 2,
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Typography
              variant="body2"
              sx={{
                color: '#666',
                fontFamily: 'Poppins, sans-serif',
                mb: 0.5,
              }}
            >
              <strong>Tier:</strong> {subData.subscription.tier || 'N/A'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#666',
                fontFamily: 'Poppins, sans-serif',
                mb: 0.5,
              }}
            >
              <strong>Subscription Fee:</strong> ${subData.subscription.subscriptionFee || 'N/A'}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#666',
                fontFamily: 'Poppins, sans-serif',
                mb: 0.5,
              }}
            >
              <strong>Subscribed On:</strong> {new Date(subData.subscribedOn).toLocaleDateString() || 'N/A'}
            </Typography>
          </Box>
        ))}
      </Stack>

      {/* Action Button */}
      <Button
        variant="contained"
        onClick={handleNavigate}
        sx={{
          width: '100%',
          py: 1.5,
          borderRadius: 3,
          bgcolor: '#6A1B9A',
          color: '#fff',
          fontWeight: 600,
          textTransform: 'none',
          '&:hover': {
            bgcolor: '#4b0e72',
          },
        }}
      >
        View Subscription
      </Button>
    </Paper>
  );
};

export default JobSeekerSubscriptionCard;
