import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, Grid, Typography, TextField, Button, MenuItem, Select } from '@mui/material';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';
// import { fetchOrganizationData } from '../../../api/employer/employerlist';
import { createSubscription, fetchBySubscriptionId, updateSubscription } from '../../../api/subscriptions/subscriptions';
import CustomSnackbar from '../../../components/ResusableComponents/CustomSnackbar';

const AddSubscriptions = () => {
    const [organizationList, setOrganizationList] = useState(null);
    // const [organizationOptions, setOrganizationOptions] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    // Form fields
    const [jobPosts, setJobPosts] = useState('');
    const [userAccess, setUserAccess] = useState('');
    const [cvDownloads, setCvDownloads] = useState('');
    const [invites, setInvites] = useState('');
    const [tier, setTier] = useState('');
    const [subscriptionFee, setSubscriptionFee] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            if (!id) return;

            try {
                const response = await fetchBySubscriptionId(id);
                setJobPosts(response.jobPosts || '');
                setUserAccess(response.userAccess || '');
                setCvDownloads(response.cvDownloads || '');
                setInvites(response.invites || '');
                setTier(response.tier || '');
                setSubscriptionFee(response.subscriptionFee || '');
                setOrganizationList(response.client || null);
            } catch (error) {
                console.error('Error fetching subscription details:', error);
                setSnackbarOpen(true);
                setSnackbarSeverity("error");
                setSnackbarMessage('Failed to load subscription details');
            }
        };

        fetchSubscriptionDetails();
    }, [id]);

    // useEffect(() => {
    //     const loadOrganizations = async () => {
    //         try {
    //             const organizations = await fetchOrganizationData();
    //             setOrganizationOptions(organizations);
    //         } catch (error) {
    //             console.error('Failed to fetch organizations:', error);
    //         }
    //     };
    //     loadOrganizations();
    // }, []);

    const handleSubmit = async () => {
        const payload = {
            jobPosts: parseInt(jobPosts, 10),
            userAccess: parseInt(userAccess, 10),
            cvDownloads: parseInt(cvDownloads, 10),
            invites: parseInt(invites, 10),
            tier,
            subscriptionFee: parseInt(subscriptionFee, 10),
            client: organizationList, // Organization ID
        };

        try {
            let response;

            if (id) {
                // Update existing subscription
                response = await updateSubscription(id, payload);
                console.log('Subscription updated successfully:', response);
                setSnackbarMessage("Subscription updated successfully!");
            } else {
                // Create new subscription
                response = await createSubscription(payload);
                console.log('Subscription created successfully:', response);
                setSnackbarMessage("Subscription created successfully!");
            }

            // Show success snackbar
            setSnackbarOpen(true);
            setSnackbarSeverity("success");

            // Redirect after a short delay
            setTimeout(() => {
                navigate('/subscriptions');
            }, 500);

        } catch (error) {
            console.error('Error during subscription submission:', error);

            // Show error snackbar
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(error?.message || 'Failed to process subscription');
        }
    };

    return (
        <AdminDrawerList>
            <Paper
                elevation={0}
                sx={{
                    margin: '0 auto',
                    padding: '20px',
                    backgroundColor: 'white',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '800px',
                    width: '100%',
                }}
            >
                <Typography variant="h6" component="div" gutterBottom>
                    {id ? 'Edit Subscription' : 'Add Subscription'}
                </Typography>
                <Grid container spacing={2}>
                    {/* <Grid item xs={12} sm={6}>
                        <Typography component="legend">Select Organization</Typography>
                        <Autocomplete
                            options={organizationOptions}
                            getOptionLabel={(option) => option.name || ''}
                            value={organizationOptions.find((opt) => opt.id === organizationList) || null}
                            onChange={(event, newValue) => setOrganizationList(newValue?.id || null)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    placeholder="Select Organization"
                                    variant="outlined"
                                    size="small"
                                    sx={{ marginBottom: 2, marginTop: 1 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Job Posts</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Job Posts"
                            value={jobPosts}
                            onChange={(e) => setJobPosts(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">User Access</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter User Access"
                            value={userAccess}
                            onChange={(e) => setUserAccess(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">CV Downloads</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter CV Downloads"
                            value={cvDownloads}
                            onChange={(e) => setCvDownloads(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Invites</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Invites"
                            value={invites}
                            onChange={(e) => setInvites(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Tier</Typography>
                        <Select
                            fullWidth
                            value={tier}
                            onChange={(e) => setTier(e.target.value)}
                            variant="outlined"
                            size="small"
                            displayEmpty
                            sx={{ marginBottom: 2 }}
                        >
                            <MenuItem value="" disabled>
                                Select Tier
                            </MenuItem>
                            <MenuItem value="free">Free</MenuItem>
                            <MenuItem value="general">General</MenuItem>
                            <MenuItem value="premium">Premium</MenuItem>
                        </Select>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Subscription Fee</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Subscription Fee"
                            value={subscriptionFee}
                            onChange={(e) => setSubscriptionFee(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                </Grid>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ marginTop: 2 }}
                >
                    {id ? 'Update Subscription' : 'Create Subscription'}
                </Button>
            </Paper>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </AdminDrawerList>
    );
};

export default AddSubscriptions;
