import React from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable from '../../Tables/DataTables';


export default function CandidateTable({ jobDetails }) {
  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    navigate(`/jobseeker/personalProfile/${rowData.id}`, { state: rowData });
  }

  const columns = [
    { field: 'siNo', headerName: 'SI No', minWidth: 80, headerClassName: 'data-grid-header' },
    {
      field: 'profileImage', headerName: 'Profile Image', flex: 1, headerClassName: 'data-grid-header', renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '7.5vh' }}><img src={params.value} alt="Profile" style={{ width: '50px', borderRadius: '50%' }} /></div>
      )
    },
    {
      field: 'name', headerName: 'Name', flex: 1, renderCell: (params) => (
        <div style={{ cursor: 'pointer' }} onClick={() => handleViewClick(params.row)}>
          {params.value}
        </div>
      ), headerClassName: 'data-grid-header'
    },
    { field: 'email', headerName: 'Email', flex: 1, headerClassName: 'data-grid-header' },
    { field: 'phone', headerName: 'Phone Number', flex: 1, headerClassName: 'data-grid-header' },
    { field: 'preferredLocation', headerName: 'Preferred Location', minWidth: 200, headerClassName: 'data-grid-header' },
    { field: 'skills', headerName: 'Skills', flex: 1, headerClassName: 'data-grid-header' },
    { field: 'languages', headerName: 'Languages', flex: 1, headerClassName: 'data-grid-header' },

    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      headerClassName: 'data-grid-header',
    },
  ];

  // Transform the `appliedCandidates` data into the required `rows` format
  const rowsData =
    jobDetails?.appliedCandidates?.map((candidate, index) => ({
      siNo: index + 1,
      profileImage: candidate.profileImage || 'https://via.placeholder.com/50', // Placeholder image if missing
      id: candidate._id || index, // Ensure a unique ID for each row
      name: candidate.fullName || 'N/A',
      email: candidate.email || 'N/A',
      phone: candidate.phone || 'N/A',
      preferredLocation: candidate.knownLanguage?.join(', ') || 'N/A', // Combine languages
      skills: candidate.skills?.join(', ') || 'N/A', // Combine skills
      languages: candidate.knownLanguage?.join(', ') || 'N/A', // Combine languages
      createdAt: new Date(
        candidate.appliedJobs?.[0]?.appliedAt || candidate.createdAt
      ).toLocaleDateString() || 'N/A', // Format the applied date
    })) || [];
  return <DataTable columns={columns} rows={rowsData} />;
}
