import React, { useState, useEffect } from "react";
import AdminDrawerList from "../../../components/Drawer/AdminDrawerList";
import { fetchEmployerById } from "../../../api/employer/employerlist";
import CustomSnackbar from "../../../components/ResusableComponents/CustomSnackbar";
import SkeletonTable from "../../../components/Tables/SkeletonTable";
import {
  Grid,
  Typography,
  Paper,
  Divider,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import baseUrl from "../../../config";
import VerifiedIcon from "../../../assets/verified.png";
import { generateRecruiterAdminPassword } from "../../../api/employer/employerdetails";

const EmployerDetails = ({ organizationId }) => {
  const [data, setData] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");

  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(data?.isVerified);

  const [isJobPostingBlocked, setIsJobPostingBlocked] = useState(false);
  const [isInviteBlocked, setIsInviteBlocked] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);

  const handleBlock = async (blockType) => {
    const token = getToken();
    const status =
      blockType === "jobPosting" ? !isJobPostingBlocked : !isInviteBlocked;

    try {
      const response = await fetch(`${baseUrl}/sub/${data?.orgId}/block`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          blockType,
          status,
        }),
      });

      if (response.ok) {
        if (blockType === "jobPosting") {
          setIsJobPostingBlocked(status);
        } else if (blockType === "invite") {
          setIsInviteBlocked(status);
        }

        setSnackbarMessage(
          `${blockType === "jobPosting" ? "Job Posting" : "Invites"} ${
            status ? "blocked" : "unblocked"
          } successfully.`
        );
        setSnackbarSeverity("success");
      } else {
        console.error("Failed to update block status");
        setSnackbarMessage("Failed to update block status");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Error updating block status");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    setIsVerified(data?.isVerified);
  }, [data?.isVerified]);

  // Handle dialog close without submission
  const handleDialogClose = () => {
    setRejectDialogOpen(false);
  };

  console.log("data", data);

  const transformData = (rawData) => {
    const activeSubscription =
      Array.isArray(rawData?.subscription) &&
      rawData.subscription.find((sub) => !sub.expired);

    const formatDate = (dateString) => {
      if (!dateString) return "N/A";
      const date = new Date(dateString);
      return new Intl.DateTimeFormat(undefined, {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(date);
    };

    const transformedRecruiters = Array.isArray(rawData?.recruiters)
      ? rawData.recruiters.map((recruiter) => ({
          recruiterId: recruiter._id || "N/A",
          name: recruiter.rcName || "N/A",
          email: recruiter.rcEmail || "N/A",
          contactNumber: recruiter.rcPhone || "N/A",
          joinedDate: formatDate(recruiter.createdAt),
          role: recruiter.role || "N/A",
          reportingManager: recruiter.reportingManager || "N/A",
          permissions: recruiter.permissions || {},
          isDeleted: recruiter.deleted || false,
        }))
      : [];
    const transformedRecruiterAdmins = Array.isArray(rawData?.recruiterAdmins)
      ? rawData.recruiterAdmins.map((admin) => ({
          adminId: admin._id || "N/A",
          name: admin.rcName || "N/A",
          email: admin.rcEmail || "N/A",
          password: admin.password || null,
          contactNumber: admin.rcPhone || "N/A",
          joinedDate: formatDate(admin.createdAt),
          role: admin.role || "N/A",
          permissions: admin.permissions || {},
          isDeleted: admin.deleted || false,
          organization: admin.organization || "N/A",
        }))
      : [];

    return {
      orgId: rawData?._id || "N/A",
      organizationId: rawData?.organizationId || "N/A",
      organizationName: rawData.organizationName || "N/A",
      orgType: rawData.orgType || "N/A",
      organizationAddress: rawData.organizationAddress || "N/A",
      contactNumber: rawData.contactNumber || "N/A",
      email: rawData.email || "N/A",
      orgState: rawData.orgState || "N/A",
      marketingOrder: rawData.marketingOrder || "N/A",
      createdAt: rawData.createdAt || "N/A",
      creatorFullName: rawData.creatorFullName || "N/A",
      isVerified: rawData.isVerified || false,
      isPremium: rawData.isPremium || false,
      isApproved: rawData.isApproved || false,
      taManagerContact: rawData?.taMangerContact,
      taMangerName: rawData?.taMangerName,
      website: rawData?.website,
      dataBaseSubscribed: rawData?.dataBaseSubscribed,
      // CV Download Quota
      defaultCVDownloadAccess:
        rawData?.cvDownload?.defaultCVDownloadAccess || 0,
      purchasedCVDownloadAccess:
        rawData?.cvDownload?.purchasedCVDownloadAccess || 0,
      totalCVDownloadAccess: rawData.cvDownload?.totalCVDownloadAccess || 0,
      releasedCVDownloadAccess:
        rawData?.cvDownload?.releasedCVDownloadAccess || 0,
      remainingCVDownloadAccess:
        rawData?.cvDownload?.remainingCVDownloadAccess || 0,
      usedCVDownloadAccess: rawData?.cvDownload?.defaultCVDownloadAccess || 0,
      // Job Posting Quota
      defaultJobPosting: rawData?.jobPosting?.defaultJobPosting || 0,
      purchasedJobPosting: rawData?.jobPosting?.purchasedJobPosting || 0,
      totalJobPostings: rawData.jobPosting?.totalJobPostings || 0,
      releasedJobPosting: rawData?.jobPosting?.releasedJobPosting || 0,
      remainingJobPosting: rawData?.jobPosting?.remainingJobPosting || 0,
      usedJobPosting: rawData?.jobPosting?.usedJobPosting || 0,
      // User Access Quota
      defaultUserAccess: rawData?.userAccess?.defaultUserAccess || 0,
      purchasedUserAccess: rawData?.userAccess?.purchasedUserAccess || 0,
      totalUserAccess: rawData.userAccess?.totalUserAccess || 0,
      releasedUserAccess: rawData?.userAccess?.releasedUserAccess || 0,
      usedUserAccess: rawData?.userAccess?.usedUserAccess || 0,
      remainingUserAccess: rawData?.userAccess?.remainingUserAccess || 0,
      // Invites Quota
      defaultInvites: rawData?.invites?.defaultInvites || 0,
      purchasedInvites: rawData?.invites?.purchasedInvites || 0,
      totalInvites: rawData.invites?.totalInvites || 0,
      releasedInvites: rawData?.invites?.releasedInvites || 0,
      usedInvites: rawData?.invites?.usedInvites || 0,
      remainingInvites: rawData?.invites?.remainingInvites || 0,
      subscriptionTier: activeSubscription?.subscription?.tier,
      subscriptionFee: activeSubscription?.subscription?.subscriptionFee,
      subscriptionJobPosts: activeSubscription?.subscription?.jobPosts,
      subscriptionInvites: activeSubscription?.subscription?.invites,
      subscriptionCVDownloads: activeSubscription?.subscription?.cvDownloads,
      subscriptionUserAccess: activeSubscription?.subscription?.userAccess,
      subscriptionIsActive: activeSubscription?.subscription?.isActive,
      subscriptionId: activeSubscription?.subscription?._id,
      validTill: formatDate(activeSubscription?.validUntil),
      subscribedOn: formatDate(activeSubscription?.subscribedOn),
      recruiters: transformedRecruiters,
      recruiterAdmins: transformedRecruiterAdmins,
    };
  };

  const quotaSections = [
    {
      title: "CV Download Quota",
      fields: [
        {
          label: "Default CV Download Access",
          value: data?.defaultCVDownloadAccess,
        },
        {
          label: "Purchased CV Download Access",
          value: data?.purchasedCVDownloadAccess,
        },
        {
          label: "Total CV Download Access",
          value: data?.totalCVDownloadAccess,
        },
        {
          label: "Released CV Download Access",
          value: data?.releasedCVDownloadAccess,
        },
        {
          label: "Remaining CV Download Access",
          value: data?.remainingCVDownloadAccess,
        },
        { label: "Used CV Download Access", value: data?.usedCVDownloadAccess },
      ],
    },
    {
      title: "Job Posting Quota",
      fields: [
        { label: "Default Job Posting", value: data?.defaultJobPosting },
        { label: "Purchased Job Posting", value: data?.purchasedJobPosting },
        { label: "Total Job Postings", value: data?.totalJobPostings },
        { label: "Released Job Posting", value: data?.releasedJobPosting },
        { label: "Remaining Job Posting", value: data?.remainingJobPosting },
        { label: "Used Job Posting", value: data?.usedJobPosting },
      ],
    },
    {
      title: "User Access Quota",
      fields: [
        { label: "Default User Access", value: data?.defaultUserAccess },
        { label: "Purchased User Access", value: data?.purchasedUserAccess },
        { label: "Total User Access", value: data?.totalUserAccess },
        { label: "Released User Access", value: data?.releasedUserAccess },
        { label: "Used User Access", value: data?.usedUserAccess },
        { label: "Remaining User Access", value: data?.remainingUserAccess },
      ],
    },
    {
      title: "Invites Quota",
      fields: [
        { label: "Default Invites", value: data?.defaultInvites },
        { label: "Purchased Invites", value: data?.purchasedInvites },
        { label: "Total Invites", value: data?.totalInvites },
        { label: "Released Invites", value: data?.releasedInvites },
        { label: "Used Invites", value: data?.usedInvites },
        { label: "Remaining Invites", value: data?.remainingInvites },
      ],
    },
  ];

  useEffect(() => {
    // Skip if organizationId is not valid
    if (!organizationId) return;

    const getData = async () => {
      setLoading(true); // Start loading
      try {
        // Fetch organization data
        const organizationData = await fetchEmployerById(organizationId);
        const transformedData = transformData(organizationData);
        setData(transformedData);
        setIsVerified(transformedData.isVerified);
      } catch (error) {
        handleErrorMessage(error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    // Initial fetch or re-fetch based on shouldRefetch flag
    getData();

    // Reset shouldRefetch to prevent further re-fetches
    if (shouldRefetch) {
      setShouldRefetch(true);
    }
  }, [organizationId, shouldRefetch]); // Trigger re-fetch when organizationId or shouldRefetch changes

  const handleErrorMessage = (error) => {
    const message = error.response?.message || "Failed to fetch data.";
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleGeneratePassword = async (adminId) => {
    console.log("adminId", adminId);

    try {
        const response = await generateRecruiterAdminPassword(adminId); // Call API
        // Check if the response contains the generated password
        if (response?.generatedPassword) {
            setSnackbarMessage("Password generated successfully!"); // Show success message
            setSnackbarSeverity("success");
            setShouldRefetch(true); // Trigger data refetch
        } else {
            setSnackbarMessage("Failed to generate password!"); // Handle error response
            setSnackbarSeverity("error");
        }
    } catch (error) {
        console.error("Error generating password:", error); // Log error
        setSnackbarMessage("An error occurred while generating the password.");
        setSnackbarSeverity("error");
    } finally {
        setLoading(false); // Stop loading
    }
};

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Function to get the token
  const getToken = () => localStorage.getItem("token"); // Assuming token is stored in localStorage

  // Handle verify or unverify based on current state
  const handleVerify = async () => {
    try {
      const token = getToken(); // Get the token
      if (!isVerified) {
        // Call API to verify the Organization
        const response = await fetch(
          `${baseUrl}/organization/${organizationId}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Pass token in Authorization header
            },
          }
        );

        if (response.ok) {
          setIsVerified(true); // Update to verified state
          setSnackbarMessage("Organization verified successfully");
          setSnackbarSeverity("success");
        } else {
          setSnackbarMessage("Failed to verify Organization");
        }
      }
    } catch (error) {
      console.error("Error verifying Organization", error);
      setSnackbarMessage("Error verifying Organization");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };

  // Handle rejection submission
  const handleRejectSubmit = async () => {
    try {
      const token = getToken(); // Get the token
      const response = await fetch(
        `${baseUrl}/organization/${organizationId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Pass token in Authorization header
          },
          body: JSON.stringify({ rejectionReason }), // Pass rejection reason from state
        }
      );

      if (response.ok) {
        setSnackbarMessage(`Rejected with reason: ${rejectionReason}`);
        setIsVerified(false); // Set to unverified state
        setSnackbarSeverity("success");
      } else {
        setSnackbarMessage("Failed to reject caterer");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error rejecting caterer", error);
      setSnackbarMessage("Error rejecting caterer");
      setSnackbarSeverity("error");
    } finally {
      setRejectDialogOpen(false);
      setSnackbarOpen(true);
    }
  };

  const [isActive, setIsActive] = useState(null);
  useEffect(() => {
    if (data?.subscriptionIsActive !== undefined) {
      setIsActive(data.subscriptionIsActive);
    }
  }, [data]);
  const handleToggle = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/sub/${data?.subscriptionId}/status`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            isActive: !isActive,
          }),
        }
      );

      if (response.ok) {
        setIsActive((prev) => !prev); // Toggle active status on successful update
        setSnackbarMessage(
          `Subscription ${!isActive ? "activated" : "deactivated"} successfully`
        );
        setSnackbarSeverity("success");
      } else {
        console.error("Failed to update subscription status");
        setSnackbarMessage("Failed to update subscription status");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Error updating subscription status");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
    }
  };
  // Handle reject click (open dialog)
  const handleReject = () => {
    setRejectDialogOpen(true);
  };

  <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
    {/* Rejection Reason Dialog */}
    <Dialog open={rejectDialogOpen} onClose={handleDialogClose}>
      <DialogTitle>Reason for Rejection</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          placeholder="Rejection Reason"
          fullWidth
          variant="outlined"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogClose}>Cancel</Button>
        <Button onClick={handleRejectSubmit} variant="contained" color="error">
          Submit
        </Button>
      </DialogActions>
    </Dialog>

    {/* Snackbar for success messages */}
    <CustomSnackbar
      open={snackbarOpen}
      autoHideDuration={3000}
      onClose={() => setSnackbarOpen(false)}
      message={snackbarMessage}
    />
  </Box>;

  if (loading) {
    return (
      <AdminDrawerList>
        <SkeletonTable />
      </AdminDrawerList>
    );
  }
  return (
    <>
      <Paper elevation={0} style={{ padding: "20px", margin: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" gutterBottom>
            Employer Details
          </Typography>
          <Button
            onClick={isVerified ? handleReject : handleVerify} // Handle reject if verified, else handle verify
            sx={{
              backgroundColor: isVerified ? "transparent" : "#0e0593",
              borderRadius: "2rem",
              color: "white",
              "&:hover": {
                backgroundColor: isVerified ? "transparent" : "#0e0593",
              },
            }}
          >
            <Typography color="white">
              {isVerified ? (
                <img src={VerifiedIcon} alt="verified" onClick={handleReject} />
              ) : (
                <Typography variant="body2" fontWeight="bold">
                  Verify
                </Typography>
              )}
            </Typography>
          </Button>
        </Box>
        <Divider style={{ marginBottom: "20px" }} />

        <Grid container spacing={2}>
          {/* Organization Details */}
          <Grid item xs={12}>
            <Typography variant="h6">Organization Details</Typography>
            <Divider style={{ marginBottom: "10px" }} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Organization ID:</strong> {data?.organizationId}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Organization Name:</strong> {data?.organizationName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Organization Type:</strong> {data?.orgType}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Address:</strong> {data?.organizationAddress}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Contact Number:</strong> {data?.contactNumber}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Email:</strong> {data?.email}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>State:</strong> {data?.orgState}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Marketing Order:</strong> {data?.marketingOrder}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Created At:</strong>{" "}
              {new Date(data?.createdAt).toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Creator:</strong> {data?.creatorFullName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Talent Aquisition Contact Person:</strong>{" "}
              {data?.taMangerName}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Talent Aquisition Contact Number:</strong>{" "}
              {data?.taManagerContact}
            </Typography>
          </Grid>

          {/* Subscription and Features */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h6">Subscription and Features</Typography>
            <Divider style={{ marginBottom: "10px" }} />
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Total Job Posting Limit:</strong> {data?.totalJobPostings}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Total CV Download Limit:</strong>{" "}
              {data?.totalCVDownloadAccess}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Total User Access:</strong> {data?.totalUserAccess}{" "}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <strong>Total Invites:</strong> {data?.totalInvites}
            </Typography>
          </Grid>
          {/* Recruiter Admin details */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h6">Recruiter Admins</Typography>
            <Divider style={{ marginBottom: "10px" }} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Contact Number</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Joined Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Role</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Permissions</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Deleted</strong>
                    </TableCell>
                    {data?.recruiterAdmins?.map((admin) => (
                      <TableRow key={admin.adminId}>
                        {/* Other TableCell components */}
                        {!admin?.password && (
                          <TableCell>
                            <strong>Generate Password</strong>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.recruiterAdmins?.map((admin) => (
                    <TableRow key={admin.adminId}>
                      <TableCell>{admin.name}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.contactNumber}</TableCell>
                      <TableCell>{admin.joinedDate}</TableCell>
                      <TableCell>{admin.role}</TableCell>
                      <TableCell>
                        Invite: {admin.permissions.invite ? "Yes" : "No"},<br />
                        Review Candidates:{" "}
                        {admin.permissions.reviewCandidates ? "Yes" : "No"},
                        <br />
                        Job Posting:{" "}
                        {admin.permissions.jobPosting ? "Yes" : "No"},<br />
                        Database Search:{" "}
                        {admin.permissions.databaseSearch ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{admin.isDeleted ? "Yes" : "No"}</TableCell>
                      {!admin?.password && (
                        <TableCell>
                          <Button
                            variant="contained"
                            sx={{
                              background: "#423bfa",
                              borderRadius: "2rem",
                              textTransform: "none",
                            }}
                            onClick={() =>
                              handleGeneratePassword(admin?.adminId)
                            } // Attach handler
                            disabled={loading} // Disable button while loading
                          >
                            {loading ? "Generating..." : "Generate Password"}{" "}
                            {/* Show loading */}
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Recruiter details */}
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <Typography variant="h6">Recruiter</Typography>
            <Divider style={{ marginBottom: "10px" }} />
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Email</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Contact Number</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Joined Date</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Role</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Permissions</strong>
                    </TableCell>
                    <TableCell>
                      <strong>Deleted</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.recruiters?.map((admin) => (
                    <TableRow key={admin.adminId}>
                      <TableCell>{admin.name}</TableCell>
                      <TableCell>{admin.email}</TableCell>
                      <TableCell>{admin.contactNumber}</TableCell>
                      <TableCell>{admin.joinedDate}</TableCell>
                      <TableCell>{admin.role}</TableCell>
                      <TableCell>
                        Invite: {admin.permissions.invite ? "Yes" : "No"},<br />
                        Review Candidates:{" "}
                        {admin.permissions.reviewCandidates ? "Yes" : "No"},
                        <br />
                        Job Posting:{" "}
                        {admin.permissions.jobPosting ? "Yes" : "No"},<br />
                        Database Search:{" "}
                        {admin.permissions.databaseSearch ? "Yes" : "No"}
                      </TableCell>
                      <TableCell>{admin.isDeleted ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Divider style={{ marginBottom: "20px" }} />
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            <Card sx={{ borderRadius: 2, boxShadow: 3 }}>
              <CardContent>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="subtitle1" fontWeight="bold">
                      Current Subscription Details
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={handleToggle}
                      sx={{
                        backgroundColor: isActive ? "red" : "green",
                        "&:hover": {
                          backgroundColor: isActive ? "darkgreen" : "darkred",
                        },
                      }}
                    >
                      {isActive ? "Deactivate" : "Activate"}
                    </Button>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography>
                      <strong>Tier:</strong> {data?.subscriptionTier}
                    </Typography>
                    <Typography>
                      <strong>Subscription Fee:</strong> {data?.subscriptionFee}
                    </Typography>
                    <Typography>
                      <strong>Job Posts:</strong> {data?.subscriptionJobPosts}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Typography>
                      <strong>Invites:</strong> {data?.subscriptionInvites}
                    </Typography>
                    <Typography>
                      <strong>CV Downloads:</strong>{" "}
                      {data?.subscriptionCVDownloads}
                    </Typography>
                    <Typography>
                      <strong>User Access:</strong>{" "}
                      {data?.subscriptionUserAccess}
                    </Typography>
                    <Typography>
                      <strong>Subscribed On:</strong> {data?.subscribedOn}
                    </Typography>
                    <Typography>
                      <strong>Valid Till:</strong> {data?.validTill}
                    </Typography>
                  </Grid>
                </Grid>

                {/* New Buttons for Blocking Job Posting and Invites */}
                <Grid container spacing={2} mt={3}>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleBlock("jobPosting")}
                      sx={{
                        width: "100%",
                      }}
                    >
                      {isJobPostingBlocked
                        ? "Unblock Job Posting"
                        : "Block Job Posting"}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleBlock("invite")}
                      sx={{
                        width: "100%",
                      }}
                    >
                      {isInviteBlocked ? "Unblock Invites" : "Block Invites"}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          {quotaSections.map((section, index) => (
            <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {section.title}
                  </Typography>
                  <Grid container>
                    {section.fields.map((field, idx) => (
                      <Grid container key={idx} sx={{ mb: 1 }}>
                        <Grid item xs={7}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold" }}
                          >
                            {field.label}:
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography variant="body2">{field.value}</Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default EmployerDetails;
