import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';
import EmployerForm from './EmployerForm';
import { createOrganization, updateOrganization, fetchEmployerById } from '../../../api/employer/employerlist';
import CustomSnackbar from '../../../components/ResusableComponents/CustomSnackbar';

const CreateEmployers = () => {
    const { id } = useParams(); // Assuming you pass the ID via route params

    const [formData, setFormData] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchEmployerById(id);
                const prefilledData = {
                    companyLogo: response.companyLogo,
                    organizationName: response.organizationName,
                    orgType: response.orgType,
                    contactNumber: response.contactNumber,
                    email: response.email,
                    organizationAddress: response.organizationAddress,
                    adminFullName: response.recruiterAdmins[0]?.rcName,
                    adminPhone: response.recruiterAdmins[0]?.rcPhone,
                    adminEmail: response.recruiterAdmins[0]?.rcEmail,
                    adminPassword: response.adminPassword,

                };
                setFormData(prefilledData);

            } catch (error) {
                console.error("Error fetching caterer data:", error);
            }
        };
        if (id) {
            fetchData();
        }
    }, [id]);

    const handleSubmit = async (formData) => {
        // Prepare FormData to send
        const formDataObj = new FormData();
        formDataObj.append("organizationName", formData.organizationName);
        formDataObj.append("organizationAddress", formData.organizationAddress);
        formDataObj.append("orgType", formData.orgType);
        formDataObj.append("contactNumber", formData.contactNumber);
        formDataObj.append("email", formData.email);
        formDataObj.append("adminFullName", formData.adminFullName);
        formDataObj.append("adminPhone", formData.adminPhone);
        formDataObj.append("adminEmail", formData.adminEmail);
        formDataObj.append("adminPassword", formData.adminPassword);

        console.log("Form Data Submitted:", formData);

        try {
            let result;
            let successMessage;
            if (id) {
                // Call update API if ID exists
                result = await updateOrganization(id, formData);
                successMessage = "Organization updated successfully!";
            } else {
                // Call create API if no ID exists
                result = await createOrganization(formData);
                successMessage = "Organization created successfully!";
            }

            // Handle success or error based on `result.success`
            if (result) {
                setSnackbarMessage(successMessage);
                setSnackbarSeverity("success");
                setSnackbarOpen(true);

                // Navigate to organization list after a short delay
                setTimeout(() => {
                    navigate("/employer/employersList");
                }, 500);
            } else {
                // Handle API error
                setSnackbarMessage(
                    result.data?.message || "Failed to save organization. Please try again."
                );
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error("Error creating/updating organization:", error);
            // Handle unexpected errors
            setSnackbarMessage("An unexpected error occurred. Please try again.");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
    <AdminDrawerList>
                <Paper
                    elevation={0}
                    sx={{
                        margin: "0 auto",
                        padding: "20px",
                        backgroundColor: "white",
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <EmployerForm onSubmit={handleSubmit} initialValues={formData} // Pass the initial values to CatererForm
                    />
                </Paper>
                <CustomSnackbar
                    open={snackbarOpen}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                    onClose={handleSnackbarClose}
                />
            </AdminDrawerList>
    )
}

export default CreateEmployers