import React from 'react';
import { Box, Typography, Card, CardMedia } from '@mui/material';
import profilePhoto from '../../assets/avatarmen.jpg';

const JobSeekerResume = ({ jobSeekerDetails }) => {
  const items = [
    {
      title: 'Profile Photo',
      content: (
        <CardMedia
          component="img"
          src={jobSeekerDetails?.profileImage || profilePhoto}
          alt="Jobseeker Profile"
          sx={{
            borderRadius: '12px',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            border: '2px solid #008080',
          }}
        />
      ),
    },
    {
      title: 'Resumes',
      content: (
        <Box>
          {jobSeekerDetails?.resume?.map((doc, index) => (
            <Box key={index} sx={{ marginBottom: 3 }}>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: 1,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#555',
                }}
              >
                {doc.filename}
              </Typography>
              {doc.url.endsWith('.pdf') ? (
                <iframe
                  src={`https://docs.google.com/viewer?url=${encodeURIComponent(doc.url)}&embedded=true`}
                  title={doc.filename}
                  style={{
                    width: '100%',
                    height: '400px',
                    borderRadius: '12px',
                    border: '2px solid #ddd',
                  }}
                />
              ) : (
                <Typography
                  variant="body2"
                  sx={{ color: 'red', textAlign: 'center' }}
                >
                  Preview not available for this file type.
                </Typography>
              )}
            </Box>
          ))}
        </Box>
      ),
    },
    {
      title: 'Audio Clips',
      content: (
        <Box>
          {jobSeekerDetails?.audioCV?.map((audio, index) => (
            <Box key={index} sx={{ marginBottom: 3, textAlign: 'center' }}>
              <audio controls style={{ width: '100%', borderRadius: '12px' }}>
                <source src={audio.url} type={audio.mimetype} />
                Your browser does not support the audio tag.
              </audio>
            </Box>
          ))}
        </Box>
      ),
    },
    {
      title: 'Introduction Videos',
      content: (
        <Box>
          {jobSeekerDetails?.videoCV?.map((video, index) => (
            <Box key={index} sx={{ marginBottom: 3 }}>
              <Typography
                variant="body1"
                sx={{
                  marginBottom: 1,
                  textAlign: 'center',
                  fontWeight: 'bold',
                  color: '#555',
                }}
              >
                {video.filename}
              </Typography>
              <video
                controls
                style={{
                  width: '100%',
                  height: '200px',
                  borderRadius: '12px',
                  border: '2px solid #ddd',
                }}
              >
                <source src={video.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          ))}
        </Box>
      ),
    },
  ];

  return (
    <Box
      sx={{
        padding: { xs: 2, md: 5 },
        background: 'linear-gradient(to right, #e0f7fa, #ffffff)',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 'bold',
          marginBottom: 4,
          textAlign: 'center',
          color: '#006064',
        }}
      >
        Jobseeker Portfolio
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
          gap: 4,
        }}
      >
        {items.map((item, index) => (
          <Card
            key={index}
            sx={{
              padding: 3,
              borderRadius: '16px',
              boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
              background: 'white',
              transition: 'transform 0.2s ease-in-out',
              ':hover': {
                transform: 'scale(1.03)',
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 'bold',
                marginBottom: 2,
                textAlign: 'center',
                color: '#004d40',
              }}
            >
              {item.title}
            </Typography>
            <Box>{item.content}</Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default JobSeekerResume;
