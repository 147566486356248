import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchEmployerSupport = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/orgSupports`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the new response data to match the required fields
        const result = response.map((item) => ({
            ...item
        }));

        return result;

    } catch (error) {
        console.error('Error fetching Support details:', error);
        throw error;
    }
};

// Assuming this is your API call function
export const fetchSupportById = async (supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        const response = await fetch(`${baseUrl}/org_convo/${supportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });



        if (!response.ok) {
            console.warn("Token may be invalid or session expired.");
            return null;
        }

        const data = await response.json(); // assuming JSON response

        return data; // Extract messages from the response


    } catch (error) {
        console.error("Error fetching Support details:", error);
        throw error;
    }
};

export const sendMessage = async (payload, supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/orgSupport/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        if (response.message === 'Reply sent successfully') {
            return response.replyMessage; // Return relevant data
        } else {
            throw new Error(`Failed to Send message: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during sending message:', error);

        // Add response-specific error handling if needed
        if (error.response) {
            console.error('Error response details:', {
                status: error.response.status,
                message: error.response.statusText,
                body: await error.response.text(),
            });
        }

        throw error; // Propagate error to the calling function
    }
};

export const updateChatStatus = async (payload, supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/orgSupportStatus/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // Check if the response is okay (HTTP 2xx status)
        if (!response.ok) {
            const errorMessage = `Failed to update status. HTTP ${response.status} - ${response.statusText}`;
            console.error(errorMessage);
            const errorBody = await response.text();
            throw new Error(`${errorMessage}. Response body: ${errorBody}`);
        }

        const data = await response.json(); // Parse the JSON response

        // Validate the response content
        if (data?.message?.includes('Support ticket status updated')) {
            return data.supportTicket; // Return the relevant data
        } else {
            throw new Error(`Unexpected response: ${data.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during status update:', error.message);

        // Add specific error details if they exist
        if (error.response) {
            const errorDetails = {
                status: error.response.status,
                message: error.response.statusText,
                body: await error.response.text(),
            };
            console.error('Error response details:', errorDetails);
        }

        throw error; // Re-throw the error to propagate it
    }
};


export const fetchJobSeekerSupport = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/jsSupports`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the new response data to match the required fields
        const result = response.map((item) => ({
            ...item
        }));

        return result;

    } catch (error) {
        console.error('Error fetching Support details:', error);
        throw error;
    }
};

// Assuming this is your API call function
export const fetchJobSeekerSupportById = async (supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        const response = await fetch(`${baseUrl}/js_convo/${supportId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });



        if (!response.ok) {
            console.warn("Token may be invalid or session expired.");
            return null;
        }

        const data = await response.json(); // assuming JSON response

        return data; // Extract messages from the response


    } catch (error) {
        console.error("Error fetching Support details:", error);
        throw error;
    }
};

export const jobSeekerSendMessage = async (payload, supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/jsSupport/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        if (response.message === 'Reply sent successfully') {
            return response.replyMessage; // Return relevant data
        } else {
            throw new Error(`Failed to Send message: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during sending message:', error);

        // Add response-specific error handling if needed
        if (error.response) {
            console.error('Error response details:', {
                status: error.response.status,
                message: error.response.statusText,
                body: await error.response.text(),
            });
        }

        throw error; // Propagate error to the calling function
    }
};

export const jobSeekerUpdateChatStatus = async (payload, supportId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/jsSupportStatus/${supportId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        });

        // Check if the response is okay (HTTP 2xx status)
        if (!response.ok) {
            const errorMessage = `Failed to update status. HTTP ${response.status} - ${response.statusText}`;
            console.error(errorMessage);
            const errorBody = await response.text();
            throw new Error(`${errorMessage}. Response body: ${errorBody}`);
        }

        const data = await response.json(); // Parse the JSON response

        // Validate the response content
        if (data?.message?.includes('Support ticket status updated')) {
            return data.supportTicket; // Return the relevant data
        } else {
            throw new Error(`Unexpected response: ${data.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error during status update:', error.message);

        // Add specific error details if they exist
        if (error.response) {
            const errorDetails = {
                status: error.response.status,
                message: error.response.statusText,
                body: await error.response.text(),
            };
            console.error('Error response details:', errorDetails);
        }

        throw error; // Re-throw the error to propagate it
    }
};