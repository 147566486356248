import React from 'react';
import {Skeleton, Grid } from '@mui/material';


const SkeletonTable = () => {
    return (
        <Grid container spacing={2}>
            {[...Array(5)].map((_, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={2}>
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                    <Grid item xs={2}>
                        <Skeleton variant="rectangular" height={40} />
                    </Grid>
                </React.Fragment>
            ))}
        </Grid>
    );
}

export default SkeletonTable
