import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import { Typography, Button, Tooltip, IconButton, styled, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from '@mui/icons-material/Add';
import SeekersTable from '../../components/Tables/SeekersTable';
import { fetchAllQuotationsData, deleteQuotation, convertToSubscribe } from "../../api/quotations/quotations";
import CustomSnackbar from '../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../components/Tables/SkeletonTable';
import ConfirmDialog from '../../components/ResusableComponents/ConfirmDialog';

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
}));

const Quotations = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        const getData = async () => {
            try {
                const QuotationsData = await fetchAllQuotationsData();
                setData(
                    QuotationsData.map((item) => ({
                        ...item,
                        isSubscription: item.convertedToSubscription,
                    }))
                );
            } catch (error) {
                handleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    const handleAddQuotationsClick = () => {
        navigate('/add-quotations');
    };

    const handleEditClick = (rowData) => {
        navigate(`/edit-quotation/${rowData.id}`, { state: rowData });
    };

    const handleViewClick = (rowData) => {
        navigate(`/view-quotation/${rowData.id}`, { state: rowData });
    };

    const handleConvertToSubscribe = async (rowData) => {
        try {
            const response = await convertToSubscribe(rowData.id); // Call the conversion API
            if (response?.convertedToSubscription) {
                // Update the specific row in the data array
                setData((prevData) =>
                    prevData.map((item) =>
                        item._id === rowData._id
                            ? { ...item, isSubscription: true, ...response }
                            : item
                    )
                );
                setSnackbarMessage('Quotation successfully converted to subscription');
            } else {
                setSnackbarMessage(response.message || 'Failed to convert quotation to subscription');
            }
        } catch (error) {
            setSnackbarMessage('Error converting quotation to subscription');
            console.error('Error:', error);
        } finally {
            setSnackbarOpen(true);
        }
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            try {
                await deleteQuotation(selectedRow.id); // Perform the delete action
                setData(data.filter((row) => row._id !== selectedRow.id)); // Update the local state
                setSnackbarMessage('Quotation deleted successfully');
            } catch (error) {
                const errorMessage =
                    error?.response?.data?.message ||
                    error?.message ||
                    "Error deleting Quotation. Please try again.";
                setSnackbarMessage(errorMessage);
            } finally {
                setSnackbarOpen(true);
                setOpenDialog(false);
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleErrorMessage = (error) => {
        const message = error.response?.message || "An error occurred.";
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const columns = [
        { field: 'siNo', headerName: 'SI No', minWidth: 60, sortable: true },
        {
            field: 'organizationName',
            headerName: 'Company Name',
            minWidth: 240,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleViewClick(params.row)}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'contactNumber', headerName: 'Phone Number', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'organizationAddress', headerName: 'Address', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'cvAccess', headerName: 'CV Access', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'pricePerCVAccess', headerName: 'Price Per CV', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'cvDownloadAccess', headerName: 'CV Downloads', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'pricePerCVDownload', headerName: 'Price Per CV Download', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'jobPostAccess', headerName: 'Job Post Access', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'pricePerJobPost', headerName: 'Price Per Job Post', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'grossAmount', headerName: 'Gross Amount', minWidth: 150, headerClassName: 'data-grid-header' },
        { field: 'gst', headerName: 'GST', minWidth: 100, headerClassName: 'data-grid-header' },
        { field: 'totalCost', headerName: 'Total Cost', minWidth: 150, headerClassName: 'data-grid-header' },
        {
            field: 'convertedToSubscription',
            headerName: 'Subscription',
            minWidth: 300,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Convert to Subscription">
                        <Button
                            disabled={!!params.row.isSubscription}
                            variant="contained"
                            sx={{ mr: '10px' }}
                            onClick={() => handleConvertToSubscribe(params.row)}
                        >
                            {params.row.isSubscription ? 'Subscription Converted' : 'Convert to Subscription'}
                        </Button>
                    </Tooltip>
                </div>
            ),
        },

        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="View">
                        <IconButton color="default" onClick={() => handleViewClick(params.row)}>
                            <VisibilityIcon sx={{ mr: '10px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton color="success" onClick={() => handleEditClick(params.row)}>
                            <EditIcon sx={{ mr: '10px' }} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon sx={{ mr: '10px' }} />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <AdminDrawerList>
            <Paper
                elevation={0}
                sx={{
                    margin: "40px 0",
                    padding: "20px",
                    flex: 1,
                    height: 'max-content',
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <Typography variant="h4" fontWeight="bolder">Quotations</Typography>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<AddIcon />}
                        onClick={handleAddQuotationsClick}
                    >
                        Add Quotations
                    </Button>
                </ButtonContainer>
                {loading ? (
                    <SkeletonTable />
                ) : (
                    <SeekersTable rows={data} columns={columns} disableRowSelected />
                )}
            </Paper>
            <ConfirmDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={confirmDelete}
                title="Confirm Deletion"
                content="Are you sure you want to delete this Quotation?"
            />
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarMessage?.includes('successfully') ? 'success' : 'error'}
            />
        </AdminDrawerList>
    );
};

export default Quotations;