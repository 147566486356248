import React from "react";
import { Paper, Typography, Button, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const SubscriptionCard = ({ handleNavigate, subscriptionData }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        bgcolor: "background.paper",
        boxShadow: 3,
        borderRadius: 3,
        padding: "1.8rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {/* Header Section with Company Logo and Name */}
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {subscriptionData?.logo ? (
            <img
              src={subscriptionData.logo}
              alt="Company Logo"
              style={{
                width: 60,
                height: 60,
                borderRadius: "50%",
                marginRight: 20,
                objectFit: "cover",
              }}
            />
          ) : (
            <div
              style={{
                width: 60,
                height: 60,
                borderRadius: "50%",
                backgroundColor: "#6A1B9A", // Default background color
                color: "#fff", // Default text color
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 24,
                marginRight: 20,
                fontWeight: "bold",
              }}
            >
              {subscriptionData?.organizationName?.charAt(0).toUpperCase() ||
                "-"}
            </div>
          )}
        </div>

        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 600,
              color: "#6A1B9A",
              fontFamily: "Poppins, sans-serif",
              marginBottom: "0.2rem",
            }}
          >
            {subscriptionData?.organizationName}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: "#6A1B9A",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {subscriptionData?.tier
              ? subscriptionData.tier.charAt(0).toUpperCase() +
                subscriptionData.tier.slice(1)
              : ""}{" "}
            Subscription Plan
          </Typography>
        </Box>
      </Box>

      {/* Subscription Price */}
      <Typography
        variant="h5"
        sx={{
          color: "#51ce8a",
          fontWeight: 600,
          marginBottom: 1,
          fontFamily: "Poppins, sans-serif",
        }}
      >
        ₹ {subscriptionData?.subscriptionFee} /-
      </Typography>

      {/* Subscription Validity (Left and Right) */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "#999",
            fontSize: "0.8rem",
            fontWeight: 300,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Valid from: {subscriptionData?.subscribedOn}
        </Typography>
      </Box>

      {/* Subscription Status and Location (Left and Right) */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon sx={{ color: "#777", fontSize: "18px" }} />
          <Typography
            variant="body2"
            sx={{
              color: "#777",
              fontSize: "14px",
              fontWeight: 400,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {subscriptionData?.organizationAddress}
          </Typography>
        </Box>
      </Box>

      {/* Action Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={handleNavigate}
        sx={{
          width: "100%",
          fontSize: "14px",
          padding: "12px 0",
          background: "#6A1B9A",
          textTransform: "none",
          boxShadow: 3,
          "&:hover": {
            backgroundColor: "#4b0e72", // Darker shade on hover
          },
        }}
      >
        View Subscription
      </Button>
    </Paper>
  );
};

export default SubscriptionCard;
