import React from 'react'
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList'

const ViewEmployerPromoDetails = () => {
  return (
    <AdminDrawerList>
      <div>ViewEmployerPromoDetails</div>
    </AdminDrawerList>
  )
}

export default ViewEmployerPromoDetails