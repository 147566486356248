import baseUrl from "../../config";

export const generateRecruiterAdminPassword = async (adminId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/organization/generatePassword/${adminId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        const responseData = await response.json(); // Parse response as JSON
        // Check if the response contains the generated password
        if (responseData?.generatedPassword) {
            return responseData; // Return the parsed response data with password
        } else {
            throw new Error(`Failed to generate password: ${responseData.error || 'Unknown error'}`);
        }

    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate error to be handled elsewhere
    }
};