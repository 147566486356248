import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Typography, Box, Divider } from '@mui/material';
import { fetchByQuotationId } from '../../api/quotations/quotations';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList'

const QuotationDetails = () => {
    const { id } = useParams();
    const [quoteDetails, setQuoteDetails] = useState(null);

    useEffect(() => {
        const fetchQuotationDetails = async () => {
            if (!id) return;

            try {
                const response = await fetchByQuotationId(id);
                setQuoteDetails(response);
            } catch (error) {
                console.error('Error fetching quotation details:', error);
            }
        };

        fetchQuotationDetails();
    }, [id]);

    if (!quoteDetails) return null;  // If data is not loaded yet, render nothing or a loading spinner.

    const { client, cvAccess, pricePerCVAccess, tcCVAccess, grossAmount, gst, totalCost } = quoteDetails;

    // Destructuring client details
    const { organizationName, organizationAddress, contactNumber, email, organizationId } = client;

    return (
        <AdminDrawerList>
            <Paper elevation={3} sx={{ padding: '2rem', borderRadius: '1rem', maxWidth: '800px', margin: 'auto', bgcolor: 'background.paper' }}>
                <Typography variant="h5" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Quotation Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                
                {/* Client Information */}
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif' }}>
                        Organization: {organizationName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Organization ID: {organizationId}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Address: {organizationAddress}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Contact: {contactNumber}
                    </Typography>
                    <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Email: {email}
                    </Typography>
                </Box>

                {/* CV Access Details */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    CV Access Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        CV Access: {cvAccess}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Price per CV Access: {pricePerCVAccess} INR
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Total CV Access Cost: {tcCVAccess} INR
                    </Typography>
                </Box>

                {/* Cost Summary */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Cost Summary
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Gross Amount: {grossAmount} INR
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        GST: {gst} INR
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Total Cost: {totalCost} INR
                    </Typography>
                </Box>
            </Paper>
        </AdminDrawerList>
    )
}

export default QuotationDetails;
