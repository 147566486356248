import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { teal, blue } from "@mui/material/colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const JobseekerDetails = ({ jobSeekerDetails }) => {
  const [viewedPage, setViewedPage] = useState(0);
  const [viewedRowsPerPage, setViewedRowsPerPage] = useState(5);
  const [savedPage, setSavedPage] = useState(0);
  const [savedRowsPerPage, setSavedRowsPerPage] = useState(5);
  const [tabValue, setTabValue] = useState(0); // State to manage the active tab

  if (!jobSeekerDetails) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Skeleton variant="text" width={250} height={40} />
        <Skeleton variant="rectangular" height={300} />
      </Box>
    );
  }

  const {
    viewedJobs = [],
    savedJobs = [],
    appliedJobs = [],
    contactedHR = [],
    profileViewedBy = [],
  } = jobSeekerDetails;

  const handleViewedChangePage = (event, newPage) => {
    setViewedPage(newPage);
  };

  const handleViewedChangeRowsPerPage = (event) => {
    setViewedRowsPerPage(parseInt(event.target.value, 10));
    setViewedPage(0);
  };

  const handleSavedChangePage = (event, newPage) => {
    setSavedPage(newPage);
  };

  const handleSavedChangeRowsPerPage = (event) => {
    setSavedRowsPerPage(parseInt(event.target.value, 10));
    setSavedPage(0);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Tabs Section */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="jobseeker tabs"
        sx={{ mb: 3 }}
      >
        <Tab label="Jobs" />
        <Tab label="Profile Perfomance" />
      </Tabs>

      {/* Tabs Content */}
      {tabValue === 0 && (
        <>
          {/* Viewed Jobs Section */}
          <Card sx={{ mb: 3, boxShadow: 3 }}>
            <CardContent sx={{ backgroundColor: teal[50] }}>
              <Typography variant="h4" color={teal[800]} gutterBottom>
                Viewed Jobs
              </Typography>
              <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: teal[100] }}>
                    <TableRow>
                      <TableCell>
                        <strong>Job Title</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Job Role</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Organization Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Location</strong>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {viewedJobs
                      .slice(
                        viewedPage * viewedRowsPerPage,
                        viewedPage * viewedRowsPerPage + viewedRowsPerPage
                      )
                      .map((job, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{job?.jobId?.jobTitle || "N/A"}</TableCell>
                          <TableCell>{job?.jobId?.jobRole || "N/A"}</TableCell>
                          <TableCell>{job?.jobId?.organizationId?.organizationName || "N/A"}</TableCell>
                          <TableCell>{job?.jobId?.jobLocation || "N/A"}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={viewedJobs.length}
                  rowsPerPage={viewedRowsPerPage}
                  page={viewedPage}
                  onPageChange={handleViewedChangePage}
                  onRowsPerPageChange={handleViewedChangeRowsPerPage}
                  sx={{ mt: 2 }}
                />
              </TableContainer>
            </CardContent>
          </Card>

          {/* Saved Jobs Section */}
          <Card sx={{ mb: 3, boxShadow: 3 }}>
            <CardContent sx={{ backgroundColor: blue[50] }}>
              <Typography variant="h4" color={blue[800]} gutterBottom>
                Saved Jobs
              </Typography>
              <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: blue[100] }}>
                    <TableRow>
                      <TableCell>
                        <strong>Job Title</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Organization Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Location</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Saved At</strong>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {savedJobs
                      .slice(
                        savedPage * savedRowsPerPage,
                        savedPage * savedRowsPerPage + savedRowsPerPage
                      )
                      .map((job, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{job?.jobId?.jobTitle || "N/A"}</TableCell>
                          <TableCell>{job?.jobId?.organizationId?.organizationName || "N/A"}</TableCell>
                          <TableCell>{job?.jobId?.jobLocation || "N/A"}</TableCell>
                          <TableCell>{new Date(job?.savedAt).toISOString().split("T")[0]}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={savedJobs.length}
                  rowsPerPage={savedRowsPerPage}
                  page={savedPage}
                  onPageChange={handleSavedChangePage}
                  onRowsPerPageChange={handleSavedChangeRowsPerPage}
                  sx={{ mt: 2 }}
                />
              </TableContainer>
            </CardContent>
          </Card>

          {/* Applied Jobs Section */}
          <Card sx={{ mb: 3, boxShadow: 3 }}>
            <CardContent sx={{ backgroundColor: blue[50] }}>
              <Typography variant="h4" color={blue[800]} gutterBottom>
                Applied Jobs
              </Typography>
              <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: blue[100] }}>
                    <TableRow>
                      <TableCell>
                        <strong>Job Title</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Organization Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Location</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Applied At</strong>
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appliedJobs
                      .slice(
                        savedPage * savedRowsPerPage,
                        savedPage * savedRowsPerPage + savedRowsPerPage
                      )
                      .map((job, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{job?.jobId?.jobTitle || "N/A"}</TableCell>
                          <TableCell>
                            {job?.jobId?.organizationId?.organizationName  || "N/A"}
                          </TableCell>
                          <TableCell>{job?.jobId?.jobLocation  || "N/A"}</TableCell>
                          <TableCell>
                            {
                              new Date(job?.appliedAt)
                                .toISOString()
                                .split("T")[0]
                            }
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={savedJobs.length}
                  rowsPerPage={savedRowsPerPage}
                  page={savedPage}
                  onPageChange={handleSavedChangePage}
                  onRowsPerPageChange={handleSavedChangeRowsPerPage}
                  sx={{ mt: 2 }}
                />
              </TableContainer>
            </CardContent>
          </Card>
        </>
      )}

      {tabValue === 1 && (
        <>
          <Card sx={{ boxShadow: 3, marginBottom: 5 }}>
            <CardContent>
              <Typography variant="h4" color={blue[800]} gutterBottom>
                Contacted HR
              </Typography>
              <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: blue[100] }}>
                    <TableRow>
                      <TableCell>
                        <strong>HR Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Organization</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contactedHR.map((hr, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{hr?.rcName  || "N/A"}</TableCell>
                        <TableCell>
                          {hr?.organization?.organizationName  || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={savedJobs.length}
                  rowsPerPage={savedRowsPerPage}
                  page={savedPage}
                  onPageChange={handleSavedChangePage}
                  onRowsPerPageChange={handleSavedChangeRowsPerPage}
                  sx={{ mt: 2 }}
                />
              </TableContainer>
            </CardContent>
          </Card>
          <Card sx={{ boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h4" color={blue[800]} gutterBottom>
                Profile Viewed By
              </Typography>
              <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
                <Table>
                  <TableHead sx={{ backgroundColor: blue[100] }}>
                    <TableRow>
                      <TableCell>
                        <strong>HR Name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Organization</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {profileViewedBy.map((hr, index) => (
                      <TableRow key={index} hover>
                        <TableCell>{hr?.rcName  || "N/A"}</TableCell>
                        <TableCell>
                          {hr?.organization?.organizationName  || "N/A"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={savedJobs.length}
                  rowsPerPage={savedRowsPerPage}
                  page={savedPage}
                  onPageChange={handleSavedChangePage}
                  onRowsPerPageChange={handleSavedChangeRowsPerPage}
                  sx={{ mt: 2 }}
                />
              </TableContainer>
            </CardContent>
          </Card>
        </>
      )}
    </Box>
  );
};

export default JobseekerDetails;
