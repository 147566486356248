import React from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Typography, Box, Divider } from '@mui/material';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';

const EmployerSubscriptionDetails = () => {
    const location = useLocation();
    const allSubscription = location.state?.allSubscription;

    if (!allSubscription) {
        return <Typography>No subscription details available.</Typography>;
    }

    const {
        organizationName,
        organizationAddress,
        contactNumber,
        email,
        website,
        orgCity,
        orgState,
        orgPinCode,
        subscription,
        globalQuotas,
        jobPosting,
        cvDownload,
        invites,
        userAccess,
    } = allSubscription;

    return (
        <AdminDrawerList>
            <Paper elevation={0} sx={{ padding: '2rem', borderRadius: '1rem', maxWidth: '1000px', margin: 'auto', bgcolor: 'background.paper' }}>
                {/* Subscription Details Section */}
                <Typography variant="h5" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Subscription Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                {subscription.map((plan, index) => (
                    <Box key={index} sx={{ marginBottom: '2rem' }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif' }}>
                            Tier: {plan.tier}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            Job Posts: {plan.jobPosts}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            User Access: {plan.userAccess}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            CV Downloads: {plan.cvDownloads}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            Invites: {plan.invites}
                        </Typography>
                        <Typography variant="body2" sx={{ color: plan.isActive ? '#6A1B9A' : '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            Status: {plan.isActive ? 'Active' : 'Inactive'}
                        </Typography>
                    </Box>
                ))}

                {/* Company Details Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Company Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Name: {organizationName}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Address: {organizationAddress}, {orgCity}, {orgState} - {orgPinCode}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Contact Number: {contactNumber}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Email: {email}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Website: {website}
                    </Typography>
                </Box>

                {/* Global Quotas Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Global Quotas
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        User Access: {globalQuotas.userAccess} (Used: {globalQuotas.usedUserAccess})
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Job Posting Access: {globalQuotas.jobPostingAccess} (Used: {globalQuotas.usedJobPostingAccess})
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Invite Access: {globalQuotas.inviteAccess} (Used: {globalQuotas.usedInviteAccess})
                    </Typography>
                </Box>

                {/* Job Posting Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Job Posting Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Default Job Postings: {jobPosting.defaultJobPosting}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Purchased Job Postings: {jobPosting.purchasedJobPosting}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Remaining Job Postings: {jobPosting.remainingJobPosting}
                    </Typography>
                </Box>

                {/* CV Download Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    CV Download Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Default CV Downloads: {cvDownload.defaultCVDownloadAccess}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Purchased CV Downloads: {cvDownload.purchasedCVDownloadAccess}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Remaining CV Downloads: {cvDownload.remainingCVDownloadAccess}
                    </Typography>
                </Box>

                {/* Invites Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Invite Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Default Invites: {invites.defaultInvites}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Purchased Invites: {invites.purchasedInvites}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Remaining Invites: {invites.remainingInvites}
                    </Typography>
                </Box>

                {/* User Access Section */}
                <Typography variant="h6" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    User Access Details
                </Typography>
                <Divider sx={{ marginBottom: '1rem' }} />
                <Box sx={{ marginBottom: '2rem' }}>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Default User Access: {userAccess.defaultUserAccess}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Purchased User Access: {userAccess.purchasedUserAccess}
                    </Typography>
                    <Typography variant="body2" sx={{ marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                        Remaining User Access: {userAccess.remainingUserAccess}
                    </Typography>
                </Box>              
            </Paper>
        </AdminDrawerList>
    );
};

export default EmployerSubscriptionDetails;
