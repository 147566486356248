import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import SubscriptionCard from '../../../components/ResusableComponents/subscroptionCard';
import { fetchEmployerSubscriptionsData } from '../../../api/subscriptions/subscriptions';
import CustomSnackbar from '../../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../../components/Tables/SkeletonTable';

const EmployerSubscription = () => {
  const [subscriptionData, setSubscriptionData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");


  useEffect(() => {
    const getData = async () => {
      try {
        const SubscriptionsData = await fetchEmployerSubscriptionsData();
        setSubscriptionData(SubscriptionsData);
        setSnackbarSeverity("success")
      } catch (error) {
        handleErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);


  const handleNavigate = (allSubscription) => {
    navigate('/employer/subscriptionDetails', { state: { allSubscription } });
  };
  const handleErrorMessage = (error) => {
    const message = error.response?.message;
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  return (
    <>
      {loading ? (
        <SkeletonTable />
      ) : (
        <Grid container spacing={3} justifyContent="center">
          {/* Repeat JobCard 3 times */}
          {subscriptionData.map((subscription, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  height: '100%', // Ensures equal height
                }}
              >
                <SubscriptionCard
                  subscriptionData={subscription}
                  allSubscription={subscription.allSubscription}
                  handleNavigate={() => handleNavigate(subscription.allSubscription)} // Pass data here
                />              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </>
  );
};

export default EmployerSubscription;
