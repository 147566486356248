import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchEmployerPromoCodes = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/jsPromocodes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the response to match the required column fields
        const result = response.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            code: item.code,
            discountType: item.discountType,
            discountValue: item.discountValue,
            validFrom: new Date(item.validFrom).toLocaleDateString(),
            validUntil: new Date(item.validUntil).toLocaleDateString(),
            maxUsage: item.maxUsage,
            usageCount: item.usageCount,
            maxUsagePerUser: item.maxUsagePerUser,
            isActive: item.isActive,
        }));
        return result;

    } catch (error) {
        console.error('Error fetching promo codes data:', error);
        throw error;
    }
};




// src/api/promocodes.js
export const createEmployerPromoCode = async (promoData) => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage
  try {
    const response = await fetch(`${baseUrl}create_promoCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Add bearer token in header
      },
      body: JSON.stringify(promoData),
    });

    if (!response.ok) {
      throw new Error('Failed to create promo code');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error creating promo code:', error);
    return null;
  }
};

// src/api/promocodes/promocodeApi.js

export const updateEmployerPromoCode = async (promoCodeId, promoData) => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage
  try {
    const response = await fetch(`${baseUrl}update_promoCode/${promoCodeId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Add bearer token in header
      },
      body: JSON.stringify(promoData),
    });

    if (!response.ok) {
      throw new Error('Failed to update promo code');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating promo code:', error);
    return null;
  }
};

// src/api/promocodeapi.js

export const deleteEmployerPromoCode = async (promoCodeId) => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve token from localStorage

  try {
    const response = await fetch(`${baseUrl}delete_promoCode/${promoCodeId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}` // Add bearer token in header
      },
    });

    if (!response.ok) {
      throw new Error('Failed to delete the promo code');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error deleting promo code:', error);
    throw error;
  }
};


// src/api/promocodes.js
export const updatePromoCodeStatus = async (promocodeId, status) => {
  try {
    const response = await fetch(`${baseUrl}/promocodeStatus`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        promocodeId,
        status,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to update promo code status');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error updating promo code status:', error);
    throw error;
  }
};