import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchJobSeekerData = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/jobseekers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the response to match the required column fields
        const result = response.data.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            source: item.source,
            fullName: item.fullName,
            email: item.email,
            phone: item.phone,
            experienceType: item.experienceType,
            expectedCtc: item.expectedCtc,
            designation: item?.experiences?.[0]?.designation || '-',
            industry: item.orgType,
            totalExpInYears: item.totalExpInYears,
            currentLocation: item.currentLocation,
            preferredLocation: item.preferredLocation,
            skills: item.skills,

        }));

        return result;

    } catch (error) {
        console.error('Error fetching organization data:', error);
        throw error;
    }
};


export const fetchJobSeekerDataInternal = async (page = 10) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(
            `${baseUrl}/jobseekers?source=internal&page=${page}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            }
        );

        if (!response) {
            return;
        }

        const { data, pagination } = response;

        console.log('response',response);
        

        const result = data.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            source: item.source,
            fullName: item.fullName,
            email: item.email,
            phone: item.phone,
            gender: item.gender,
            appliedPosition: item.appliedPosition,
            preferredLocation: item.preferredLocation,
            expectedCtc: item.expectedCtc,
            currentCompany: item?.experiences?.[0]?.company,
            designation: item?.experiences?.[0]?.designation || '-',
            currentCtc: item?.experiences?.[0]?.ctc,
            experienceType: item.experienceType || '-',
            noticePeriod: item?.noticePeriod || 0,
            totalExpInYears: item.totalExpInYears || 0,
            currentLocation: item.currentLocation || '-',
            industry: item.industry || '-',
        }));

        console.log('pagination',pagination);
        

        return { result, pagination };
    } catch (error) {
        console.error('Error fetching organization data:', error);
        throw error;
    }
};


export const fetchJobSeekerDataExternal = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/jobseekers?source=external`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the response to match the required column fields
        const result = response.data.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            source: item.source,
            fullName: item.fullName,
            email: item.email,
            phone: item.phone,
            experienceType: item.experienceType,
            expectedCtc: item.expectedCtc,
            designation: item?.experiences?.[0]?.designation || '-',
            industry: item.orgType,
            totalExpInYears: item.totalExpInYears,
            currentLocation: item.currentLocation,
            preferredLocation: item.preferredLocation,
            skills: item.skills,

        }));

        return result;

    } catch (error) {
        console.error('Error fetching organization data:', error);
        throw error;
    }
};
export const fetchCandidateById = async (candId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetch(`${baseUrl}/jobseeker/${candId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
        );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response.ok) {
            throw new Error("Failed to fetch Candidate details");
        }

        // Parse and return JSON response
        return await response.json();
    } catch (error) {
        console.error("Error fetching candidate details:", error);
        throw error; // Propagate error for further handling
    }
};

export const createJobSeeker = async (payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/create_js`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        // Check if the response contains a success message
        if (response.message === 'Job seeker created successfully') {
            return response; // Assuming response.organization contains the newly created organization
        } else {
            throw new Error(`Failed to add Organization: ${response.message || 'Unknown error'}`);
        }

    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate error to be handled elsewhere
    }
};


export const uploadJobSeekers = async (formData) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetch(`${baseUrl}/uploadtracker`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`, // Authorization header
            },
            body: formData, // Pass FormData directly
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Failed to upload job seekers');
        }

        return result;
    } catch (error) {
        console.error('Error during API call:', error);
        throw error;
    }
};

