import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Typography,
    Paper,
    styled,
    Button,
    IconButton,
    Tooltip,
} from "@mui/material";
import SeekersTable from "../../../components/Tables/SeekersTable";
import { fetchEmployerPromoCodes, updateEmployerPromoCode, deleteEmployerPromoCode } from "../../../api/promocodes/promocodes";
import ConfirmDialog from "../../../components/ResusableComponents/ConfirmDialog";
import CustomSnackbar from "../../../components/ResusableComponents/CustomSnackbar";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AdminDrawerList from "../../../components/Drawer/AdminDrawerList";


const ButtonContainer = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
}));

const EmployerPromoCodes = () => {

    const [tableData, setTableData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const navigate = useNavigate();
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [selectedPromoCodeId, setSelectedPromoCodeId] = useState(null);

    const handleAddClick = () => {
        navigate("/employer/addPromocodes");
    };

    const handleEditPromoCode = (id) => {
        navigate(`/employer/editPromocodes/${id}`);
    };

    const handleViewPromoCode = (id) => {
        navigate(`/employer/viewPromocodes/${id}`);
    };

    useEffect(() => {
        const loadPromoCodes = async () => {
            try {
                const promocodes = await fetchEmployerPromoCodes();
                setTableData(promocodes);
            } catch (error) {
                console.error('Failed to fetch organizations:', error);
            }
        };
        loadPromoCodes();
    }, []);


    // Function to update promo code status
    const handlePromoCodeAction = async (promocodeId, status) => {
        try {
            await updateEmployerPromoCode(promocodeId, status);
            setSnackbarMessage(
                `Promo code ${status === "approved" ? "approved" : "rejected"
                } successfully`
            );
            setSnackbarSeverity("success");
            setSnackbarOpen(true);

            // Refresh the promo code list after action
            const response = await fetchEmployerPromoCodes();
            setTableData(response);
        } catch (error) {
            setSnackbarMessage("Failed to update promo code status");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const columns = [
        { field: "siNo", headerName: "SI NO", minWidth: 10, sortable: true },
        { field: "code", headerName: "Promo Code", minWidth: 200, sortable: true },
        {
            field: "discountType",
            headerName: "Discount Type",
            minWidth: 200,
            sortable: true,
        },
        {
            field: "discountValue",
            headerName: "Discount Value",
            minWidth: 200,
            sortable: true,
        },
        {
            field: "maxUsage",
            headerName: "Max Usage",
            minWidth: 250,
            sortable: true,

        },
        {
            field: "maxUsagePerUser",
            headerName: "Max Usage Per User",
            minWidth: 250,
            sortable: true,

        },
        {
            field: "usageCount",
            headerName: "Usage Count",
            minWidth: 250,
            sortable: true,

        },

        {
            field: "isActive",
            headerName: "Status",
            minWidth: 200,
            sortable: true,
        },
        {
            field: "validFrom",
            headerName: "Valid From",
            minWidth: 200,
            sortable: true,
        },
        {
            field: "validUntil",
            headerName: "Valid Until",
            minWidth: 200,
            sortable: true,
        },
        {
            field: "takeAction",
            headerName: "Take Action",
            minWidth: 250,
            sortable: true,
            renderCell: (params) => {
                const isApproved = params.row.approvalStatus === "approved";
                const isRejected = params.row.approvalStatus === "rejected";

                return (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        {/* Accept button */}
                        <Tooltip title="Accept">
                            <span>
                                <Button
                                    variant="contained"
                                    disabled={isApproved} // Only disable if it's already approved
                                    onClick={() =>
                                        handlePromoCodeAction(params.row._id, "approved")
                                    }
                                    style={{ marginRight: "10px" }} // Add margin to the right
                                >
                                    {isApproved ? "Approved" : "Accept"}
                                </Button>
                            </span>
                        </Tooltip>

                        {/* Reject button */}
                        <Tooltip title="Reject">
                            <span>
                                <Button
                                    variant="contained"
                                    disabled={isRejected} // Only disable if it's already rejected
                                    onClick={() =>
                                        handlePromoCodeAction(params.row._id, "rejected")
                                    }
                                >
                                    {isRejected ? "Rejected" : "Reject"}
                                </Button>
                            </span>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            field: "Actions",
            headerName: "Actions",
            minWidth: 120,
            sortable: false,
            renderCell: (params) => (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    }}
                >                <Tooltip title="View">
                        <IconButton
                            sx={{ color: "#87CEEB", marginRight: "8px" }}
                            onClick={() => handleViewPromoCode(params.row.id)}
                        >
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton
                            color="success"
                            onClick={() => handleEditPromoCode(params.row.id)}
                        >
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            color="error"
                            onClick={() => handleOpenConfirmDialog(params.row.id)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];


    const handleOpenConfirmDialog = (promoCodeId) => {
        setSelectedPromoCodeId(promoCodeId);
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
        setSelectedPromoCodeId(null);
    };

    const handleDeletePromoCode = async () => {
        if (selectedPromoCodeId) {
            try {
                await deleteEmployerPromoCode(selectedPromoCodeId);
                handleCloseConfirmDialog();
                const promoCodes = await fetchEmployerPromoCodes(selectedPromoCodeId);
                const formattedData = promoCodes?.map((promo, index) => ({
                    id: index + 1,
                    code: promo.code,
                    discountType: promo.discountType,
                    discountValue: promo.discountValue,
                    minOrderValue: promo.minOrderValue,
                    createdByRole: promo.createdBy?.role,
                    userUsageLimit: promo.userUsageLimit,
                    createdAt: new Date(promo.createdAt).toLocaleString(),
                    expiryDate: new Date(promo.expiryDate).toLocaleDateString(),
                    promoDescription: promo.promoDescription,
                    maxAmount: promo.maxAmount,
                    adminContri: promo.adminContri,
                    availableFrom: new Date(promo.availableFrom).toLocaleDateString(),
                    status: promo.status,
                    promocodeType: promo?.promocodeType.join(", "),
                    paymentMethods: promo.paymentMethods.join(", "),
                    _id: promo._id,
                }));
                setTableData(formattedData);
                setSnackbarMessage("Successfully Deleted PromoCode");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
            } catch (error) {
                console.error("Error deleting promo code:", error);
                setSnackbarMessage("Error deleting promo code");
                setSnackbarSeverity("error");
                setSnackbarOpen(true);
            }
        }
    };

    return (
        <AdminDrawerList>
            <Paper
                elevation={0}
                sx={{
                    margin: "40px 0",
                    padding: "20px",
                    flex: 1,
                    height: "max-content",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <Typography variant="h4" fontWeight="bolder">
                    Employer PromoCodes
                </Typography>
                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<AddIcon />}
                        onClick={handleAddClick}
                    >
                        Add Employer PromoCodes
                    </Button>
                </ButtonContainer>

                <SeekersTable
                    rows={tableData}
                    columns={columns}
                />

            </Paper>
            <ConfirmDialog
                open={openConfirmDialog}
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDeletePromoCode}
                title="Confirm Delete"
                message="Are you sure you want to delete this promo code?"
            />
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

        </AdminDrawerList>
    );
};

export default EmployerPromoCodes;
