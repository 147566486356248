import React from 'react';
import { Box, Typography, Grid, Paper, Divider } from '@mui/material';
import { styled } from '@mui/system';

// Define the styles using styled-components (MUI's styled API)
const StyledTypography = styled(Typography)({
    color: '#a1a4b5',
    marginBottom: '0.1rem',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '14px',
    textAlign: 'justify',
    fontFamily: 'Poppins, sans-serif',
});

const JobDescription = ({ jobDetails }) => {
    return (
        <Paper elevation={0} sx={{ padding: 1, borderRadius: 2 }}>
            <Box>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={2}>
                    {/* Job Description */}
                    <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold">
                            Job Description:
                        </Typography>
                        <StyledTypography variant="body1" component="div">
                            <ul>
                                thanks a lot of the first one is there for me in the morning
                            </ul>
                        </StyledTypography>
                    </Grid>

                    <Grid item xs={12}>
                        <StyledTypography variant="body1">
                            Expected Start Date: {jobDetails?.createdAt ? new Date(jobDetails.createdAt).toLocaleString() : 'N/A'}
                        </StyledTypography>
                    </Grid>

                    {/* Job Type */}
                    <Grid item xs={12}>
                        <StyledTypography variant="body1">
                            Preffered: {jobDetails?.experienceType}
                        </StyledTypography>
                    </Grid>

                    {/* Salary */}
                    <Grid item xs={12}>
                        <StyledTypography variant="body1">
                            Salary: From {jobDetails?.ctc} per year
                        </StyledTypography>
                    </Grid>

                    <Divider sx={{ marginBottom: 1, marginTop: 1, width: '100%' }} />

                    {/* Contact Information */}
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" fontWeight="bold">
                            Speak with the Recruiter:
                        </Typography>
                        <StyledTypography variant="body1">
                            {jobDetails?.postedBy?.rcName}
                        </StyledTypography>
                        <StyledTypography variant="body1">
                            {jobDetails?.postedBy?.rcEmail}
                        </StyledTypography>
                        <StyledTypography variant="body1">
                            {jobDetails?.postedBy?.role}
                        </StyledTypography>
                        <StyledTypography variant="body1">
                            {jobDetails?.postedBy?.rcPhone}
                        </StyledTypography>
                    </Grid>

                    {/* Application Questions */}
                    {/* <Grid item xs={12}>
                        <Typography variant="h6" fontWeight="bold" gutterBottom>
                            Application Questions:
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#a1a4b5', mb: 1 }}>
                            You have requested that Indeed ask candidates the following questions:
                        </Typography>
                        <StyledTypography variant="body1" component="div">
                            <ul>
                                <li>What is the highest level of education you have completed?</li>
                            </ul>
                        </StyledTypography>
                    </Grid> */}
                </Grid>
            </Box>
        </Paper>
    );
};

export default JobDescription;
