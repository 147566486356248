import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import CandidiateJobFilters from '../../../../../components/EmployerJobDetailsComponents/CandidateDetails/CandidiateJobFilters';
import CandidateTable from '../../../../../components/EmployerJobDetailsComponents/CandidateDetails/CandidiateTable';
import { fetchEmployerJobPostedDetailsById } from '../../../../../api/employer/employerjobs';
import CustomSnackbar from '../../../../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../../../../components/Tables/SkeletonTable';

const CandidateDetails = ({ jobId }) => {

    const [jobDetails, setJobDetails] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(true);

    const handleErrorMessage = (error) => {
        const message = error.response?.message || "Failed to fetch data.";
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const jobData = await fetchEmployerJobPostedDetailsById(jobId);
                setJobDetails(jobData);
                setSnackbarSeverity("success");
            } catch (error) {
                handleErrorMessage(error);
                setSnackbarSeverity("error");
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [jobId]);
    return (

        <Box
            sx={{
                gap: 1, // Add spacing between components
                margin: 2 // Optional margin for better overall spacing
            }}
        >
            {loading ? (
                <SkeletonTable />
            ) : (<><CandidiateJobFilters jobDetails={jobDetails} /><CandidateTable jobDetails={jobDetails} /><CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity} /></>)}</Box>

    );
}

export default CandidateDetails;
