import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import {
  Typography,
  Paper,
  TextField,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
} from "@mui/material";
import { createRole, updateRole } from "../../api/userRoles/roles";
import CustomSnackbar from "../../components/ResusableComponents/CustomSnackbar";
// import UserPermissions from "../../components/Roles/UserPermissions";

const AddRoles = () => {
  const location = useLocation();
  const userData = location.state || {};

  const navigate = useNavigate();
  const [role, setRole] = useState(userData.role || "");
  const [fullName, setFullName] = useState(userData.name || "");
  const [email, setEmail] = useState(userData.email || "");
  const [phoneNumber, setPhoneNumber] = useState(userData.phoneNumber || "");
  const [secretKey, setSecretKey] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "",
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const handleSecretKeyChange = (event) => {
    setSecretKey(event.target.value);
  };

  const validateFields = () => {
    const isValid = Object.values(errors).every((error) => error === "");
    if (!isValid) {
      setSnackbar({
        open: true,
        message: "Please fix the errors before submitting.",
        severity: "error",
      });
    }
    return isValid;
  };
  

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    setFullName(value);

    if (!/^[A-Za-z\s]+$/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        fullName: "Please enter a valid full name (letters and spaces only).",
      }));
    } else {
      setErrors((prev) => ({ ...prev, fullName: "" }));
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);

    if (!/^[6-9]\d{9}$/.test(value)) {
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, phoneNumber: "" }));
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/.test(
        value
      )
    ) {
      setErrors((prev) => ({
        ...prev,
        password:
          "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character.",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    // Construct the payload
    const payload = {
      fullName,
      email,
      phoneNumber,
      password: password || undefined, // Include password only if it's not empty
      role,
    };

    // Add secretKey for super_admin role, only if it's provided
    if (role === "super_admin" && secretKey) {
      payload.secretKey = secretKey;
    }

    try {
      if (userData._id) {
        // Edit existing role
        await updateRole(userData._id, payload); // Call the update API for roles
        setSnackbar({
          open: true,
          message: "Role updated successfully!",
          severity: "success",
        });
      } else {
        // Create new role
        await createRole(payload); // Call the create API for roles
        setSnackbar({
          open: true,
          message: "Role added successfully!",
          severity: "success",
        });
      }

      // Navigate to /roles after the snackbar closes
      setTimeout(() => {
        navigate("/roles");
      }, 500);
    } catch (error) {
      // Extract error message from error object or use a default message
      const errorMessage =
        error?.response?.data?.message ||
        error?.message ||
        "Error adding role. Please try again.";
      // Display the error message in the Snackbar
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div
      style={{
        backgroundColor: "#f2f2f2",
        padding: "20px",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AdminDrawerList>
        <Paper
          elevation={0}
          sx={{
            margin: "0 auto",
            padding: "20px",
            backgroundColor: "white",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Typography variant="h5" fontWeight="bolder" mb={3} gutterBottom>
            {userData._id ? "Edit Role" : "Add Role"}
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Full Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Full Name Here"
                  value={fullName}
                  onChange={handleFullNameChange}
                  name="name"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Email</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Email Here"
                  value={email}
                  onChange={handleEmailChange}
                  name="email"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 1, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                >
                  <Typography component="legend">Role</Typography>
                  <Select value={role} onChange={handleRoleChange} displayEmpty>
                    <MenuItem value="" disabled>
                      Select Role
                    </MenuItem>
                    <MenuItem value="super_admin">Super Admin</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="accounts">Accounts</MenuItem>
                    <MenuItem value="customer_support">
                      Customer Support
                    </MenuItem>
                    <MenuItem value="data_analyst">Data Analyst</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {role === "super_admin" && (
                <Grid item xs={12} sm={6}>
                  <Typography component="legend">Secret Key</Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    value={secretKey}
                    onChange={handleSecretKeyChange}
                    sx={{ marginBottom: 2, marginTop: 1 }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Phone Number</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Phone Number Here"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  name="phoneNumber"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component="legend">Password</Typography>
                <TextField
                  fullWidth
                  type="password"
                  placeholder="Enter Password Here"
                  value={password}
                  onChange={handlePasswordChange}
                  name="password"
                  variant="outlined"
                  size="small"
                  sx={{ marginBottom: 2, marginTop: 1 }}
                  InputLabelProps={{ shrink: true }}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleSubmit}
                >
                  {userData.id ? "Update" : "Add"} Role
                </Button>
              </Grid>
              {/* <UserPermissions /> */}
            </Grid>
          </Box>
        </Paper>
      </AdminDrawerList>
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default AddRoles;
