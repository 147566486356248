import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  IconButton,
} from "@mui/material";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import baseUrl from "../../config";
import {
  Email,
  Phone,
  Security,
  AdminPanelSettings,
} from "@mui/icons-material"; // Icons for visual appeal

const AdminProfile = () => {
  const [transformedData, setTransformedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRolesData = async () => {
      const token = localStorage.getItem("token")?.replace(/"/g, "");
      const userId = localStorage.getItem("userId")?.replace(/"/g, "");

      if (!token || !userId) {
        setError("No access token or userId found");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/admin/${userId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch profile data");
        }

        const data = await response.json();
        const transformed = transformData(data); // Transform the data here
        setTransformedData(transformed); // Set transformed data to state
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRolesData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function to transform the data
  const transformData = (data) => {
    // Example transformation
    return {
      ...data,
      phone: data.phoneNumber ? formatPhoneNumber(data.phoneNumber) : "Not Available", // Example transformation: format phone number
      email: data.email || "Not Available", // Default value if email is missing
      createdAt: formatDate(data.createdAt), // Format date
      updatedAt: formatDate(data.updatedAt), // Format date
      permissions: {
        reviewCandidates: data.permissions?.reviewCandidates
          ? "Allowed"
          : "Not Allowed",
        jobPosting: data.permissions?.jobPosting ? "Allowed" : "Not Allowed",
        databaseSearch: data.permissions?.databaseSearch
          ? "Allowed"
          : "Not Allowed",
      },
    };
  };

  // Helper function to format date (example: toLocaleDateString)
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  // Helper function to format phone number (simple placeholder logic)
  const formatPhoneNumber = (phone) => {
    return `+91-${phone}`; // Example format
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <AdminDrawerList>
      <Box sx={{ padding: 4 }}>
        <Typography variant="h3" color="primary" gutterBottom>
          Admin Profile
        </Typography>

        {transformedData ? (
          <Grid container spacing={4}>
            {/* Personal Information Section */}
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={{ borderRadius: 2, height: "300px" }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                    gutterBottom
                  >
                    Personal Information
                  </Typography>
                  <Divider sx={{ marginBottom: 2 }} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <IconButton>
                      <Phone sx={{ color: "text.secondary" }} />
                    </IconButton>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {transformedData.phone}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <IconButton>
                      <Email sx={{ color: "text.secondary" }} />
                    </IconButton>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {transformedData.email}
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ marginBottom: 1 }}
                  >
                    Account Created: {transformedData.createdAt}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Last Updated: {transformedData.updatedAt}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Role and Permissions Section */}
            <Grid item xs={12} sm={6}>
              <Card
                variant="outlined"
                sx={{ borderRadius: 2, height: "300px" }}
              >
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{ color: "primary.main", fontWeight: 600 }}
                    gutterBottom
                  >
                    Role and Permissions
                  </Typography>
                  <Divider sx={{ marginBottom: 2 }} />
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 2,
                    }}
                  >
                    <IconButton>
                      <AdminPanelSettings sx={{ color: "text.secondary", marginRight: "5px" }} />
                    </IconButton>
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      Role: {transformedData.role || "Not Assigned"}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ color: "text.primary", marginBottom: 1 }}
                  >
                    Permissions:
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Security sx={{ color: "text.secondary", marginRight: "5px" }} />
                    <Typography variant="body2">
                      Review Candidates:{" "}
                      {transformedData.permissions.reviewCandidates}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Security sx={{ color: "text.secondary", marginRight: "5px" }} />
                    <Typography variant="body2">
                      Job Posting: {transformedData.permissions.jobPosting}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <Security sx={{ color: "text.secondary", marginRight: "5px" }} />
                    <Typography variant="body2">
                      Database Search:{" "}
                      {transformedData.permissions.databaseSearch}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        ) : (
          <Typography variant="body1">No profile data found</Typography>
        )}
      </Box>
    </AdminDrawerList>
  );
};

export default AdminProfile;
