import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Paper, Grid, Typography, TextField, Button, Autocomplete, Box } from '@mui/material';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import { fetchOrganizationData } from '../../api/employer/employerlist';
import { fetchByQuotationId } from '../../api/quotations/quotations';
import { createQuotation, updateQuotation } from '../../api/quotations/quotations';
import CustomSnackbar from '../../components/ResusableComponents/CustomSnackbar';

const AddQuotations = () => {
    const [organizationList, setOrganizationList] = useState(null);
    const [organizationOptions, setOrganizationOptions] = useState([]);

    const navigate = useNavigate();
    const { id } = useParams();

    // Form fields
    const [cvAccess, setCvAccess] = useState('');
    const [pricePerCVAccess, setPricePerCVAccess] = useState('');
    const [cvDownloadAccess, setCvDownloadAccess] = useState('');
    const [pricePerCVDownload, setPricePerCVDownload] = useState('');
    const [invites, setInvites] = useState('');
    const [pricePerInvite, setPricePerInvite] = useState('');
    const [jobPostAccess, setJobPostAccess] = useState('');
    const [pricePerJobPost, setPricePerJobPost] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    useEffect(() => {
        const fetchQuotationDetails = async () => {
            if (!id) return;

            try {
                const response = await fetchByQuotationId(id);
                setCvAccess(response.cvAccess || '');
                setPricePerCVAccess(response.pricePerCVAccess || '');
                setCvDownloadAccess(response.cvDownloadAccess || '');
                setPricePerCVDownload(response.pricePerCVDownload || '');
                setInvites(response.invites || '');
                setPricePerInvite(response.pricePerInvite || '');
                setJobPostAccess(response.jobPostAccess || '');
                setPricePerJobPost(response.pricePerJobPost || '');
                setOrganizationList(response.client || null);
            } catch (error) {
                console.error('Error fetching subscription details:', error);
                setSnackbarOpen(true);
                setSnackbarSeverity("error");
                setSnackbarMessage('Failed to load subscription details');
            }
        };

        fetchQuotationDetails();
    }, [id]);

    useEffect(() => {
        const loadOrganizations = async () => {
            try {
                const organizations = await fetchOrganizationData();
                setOrganizationOptions(organizations);
            } catch (error) {
                console.error('Failed to fetch organizations:', error);
            }
        };
        loadOrganizations();
    }, []);

    const handleSubmit = async () => {
        const payload = {
            client: organizationList, // Organization ID
            cvAccess: parseInt(cvAccess, 10),
            pricePerCVAccess: parseFloat(pricePerCVAccess),
            cvDownloadAccess: parseInt(cvDownloadAccess, 10),
            pricePerCVDownload: parseFloat(pricePerCVDownload),
            invites: parseInt(invites, 10),
            pricePerInvite: parseFloat(pricePerInvite),
            jobPostAccess: parseInt(jobPostAccess, 10),
            pricePerJobPost: parseFloat(pricePerJobPost),
        };

        try {
            let response;

            if (id) {
                // Update existing Quotation
                response = await updateQuotation(id, payload);
                console.log('Quotation updated successfully:', response);
                setSnackbarMessage("Quotation updated successfully!");
            } else {
                // Create new Quotation
                response = await createQuotation(payload);
                console.log('Quotation created successfully:', response);
                setSnackbarMessage("Quotation created successfully!");
            }

            // Show success snackbar
            setSnackbarOpen(true);
            setSnackbarSeverity("success");

            // Redirect after a short delay
            setTimeout(() => {
                navigate('/quotations');
            }, 500);

        } catch (error) {
            console.error('Error during subscription submission:', error);

            // Show error snackbar
            setSnackbarOpen(true);
            setSnackbarSeverity("error");
            setSnackbarMessage(error?.message || 'Failed to process subscription');
        }
    };

    return (
        <AdminDrawerList>
            <Paper
                elevation={0}
                sx={{
                    margin: '0 auto',
                    padding: '20px',
                    backgroundColor: 'white',
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '800px',
                    width: '100%',
                }}
            >
                <Typography variant="h6" component="div" gutterBottom>
                    {id ? 'Edit Quotations' : 'Add Quotations'}
                </Typography>
                <Grid container spacing={2}>
                    {!id && (
                        <Grid item xs={12} sm={6}>
                            <Typography component="legend">Select Organization</Typography>
                            <Autocomplete
                                options={organizationOptions}
                                getOptionLabel={(option) => option.name || ''}
                                value={organizationOptions.find((opt) => opt.id === organizationList) || null}
                                onChange={(event, newValue) => setOrganizationList(newValue?.id || null)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        placeholder="Select Organization"
                                        variant="outlined"
                                        size="small"
                                        sx={{ marginBottom: 2, marginTop: 1 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">CV Access</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter CV Access"
                            value={cvAccess}
                            onChange={(e) => setCvAccess(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2, marginTop: 1 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Price Per CV Access</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Price Per CV Access"
                            value={pricePerCVAccess}
                            onChange={(e) => setPricePerCVAccess(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">CV Download Access</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter CV Download Access"
                            value={cvDownloadAccess}
                            onChange={(e) => setCvDownloadAccess(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Price Per CV Download</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Price Per CV Download"
                            value={pricePerCVDownload}
                            onChange={(e) => setPricePerCVDownload(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Invites</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Invites"
                            value={invites}
                            onChange={(e) => setInvites(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Price Per Invite</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Price Per Invite"
                            value={pricePerInvite}
                            onChange={(e) => setPricePerInvite(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Job Post Access</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Job Post Access"
                            value={jobPostAccess}
                            onChange={(e) => setJobPostAccess(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography component="legend">Price Per Job Post</Typography>
                        <TextField
                            fullWidth
                            placeholder="Enter Price Per Job Post"
                            value={pricePerJobPost}
                            onChange={(e) => setPricePerJobPost(e.target.value)}
                            variant="outlined"
                            size="small"
                            sx={{ marginBottom: 2 }}
                        />
                    </Grid>

                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ marginTop: 2, }}
                        >
                            {id ? 'Update Quotations' : 'Create Quotations'}
                        </Button>
                    </Grid>
                </Box>
            </Paper>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </AdminDrawerList>
    );
};

export default AddQuotations;
