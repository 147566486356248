import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchAllSubscriptionsData = async () => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/sub/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // If the fetchWithAuth returns null due to token invalidation, handle accordingly
    if (!response) {
      return; // Handle logout and redirect behavior
    }

    // Map the response to match the required column fields
    const result = response.subscriptions.map((item, index) => ({
      siNo: index + 1,
      id: item._id,
      subscriptionFee: item.subscriptionFee,
      tier: item.tier,
      jobPosts: item.jobPosts,
      userAccess: item.userAccess,
      cvDownloads: item.cvDownloads,
      invites: item.invites,
      createdAt: item.createdAt,
    }));

    return result;
  } catch (error) {
    console.error("Error fetching Subscription details:", error);
    throw error;
  }
};

export const fetchBySubscriptionId = async (subId) => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/sub/${subId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response) {
      console.warn("Token may be invalid or session expired.");
      return null;
    }

    // Check for HTTP errors
    if (!response.success) {
      throw new Error(
        response.message || "Failed to fetch Subscription details"
      );
    }

    // Return the data directly
    return response.data;
  } catch (error) {
    console.error("Error fetching Subscription details:", error);
    throw error;
  }
};

export const createSubscription = async (payload) => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/sub/create`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json", // Set header for JSON
      },
      body: JSON.stringify(payload), // Convert payload to JSON string
    });

    if (!response) {
      throw new Error("Response was null or undefined");
    }

    if (response.message === "Org subscription created successfully") {
      return response.data; // Return relevant data
    } else {
      throw new Error(
        `Failed to add Subscription: ${response.message || "Unknown error"}`
      );
    }
  } catch (error) {
    console.error("Error during subscription creation:", error);

    // Add response-specific error handling if needed
    if (error.response) {
      console.error("Error response details:", {
        status: error.response.status,
        message: error.response.statusText,
        body: await error.response.text(),
      });
    }

    throw error; // Propagate error to the calling function
  }
};

export const updateSubscription = async (subId, payload) => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/sub/edit/${subId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json", // Set header for JSON
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload), // Convert payload to JSON string
    });

    if (!response) {
      throw new Error("Response was null or undefined");
    }

    // Check if the response contains a success message
    if (response.message === "Org subscription updated successfully") {
      return response.data; // Assuming response.organization contains the newly created organization
    } else {
      throw new Error(
        `Failed to update Organization: ${response.message || "Unknown error"}`
      );
    }
  } catch (error) {
    console.error("Error:", error);
    throw error; // Propagate error to be handled elsewhere
  }
};

export const deleteSubscription = async (orgId) => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/sub/delete/${orgId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // If the response is a success, return the message directly

    if (response.message === "Org subscription deleted successfully") {
      return response; // Return success response
    } else {
      throw new Error(
        `Failed to delete role: ${response.message || "Unknown error"}`
      );
    }
  } catch (error) {
    console.error("Error deleting role:", error);
    throw error;
  }
};

export const fetchEmployerSubscriptionsData = async () => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(
      `${baseUrl}/sub/orgWithSubscriptions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Handle token invalidation or other issues
    if (!response) {
      return; // Handle logout and redirect behavior
    }

    // Map the response to match the required column fields
    const result = response.data.map((item, index) => {
      // Filter subscriptions to get only the active one (expired: false)
      const activeSubscription =
        item.subscription?.find((sub) => !sub.expired) || {};

      const formatDate = (dateString) => {
        if (!dateString) return "N/A"; // Handle undefined or null dates
        const date = new Date(dateString);
        if (isNaN(date)) return "N/A"; // Handle invalid date formats

        return new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "long", // 'long' for full month names (e.g., January), 'short' for abbreviated names
          day: "numeric",
        }).format(date);
      };

      return {
        siNo: index + 1,
        id: item._id,
        organizationName: item.organizationName,
        organizationAddress: item.organizationAddress,
        contactNumber: item.contactNumber,
        email: item.email,
        createdAt: item.createdAt,
        tier: activeSubscription?.subscription?.tier || "N/A",
        subscriptionFee: activeSubscription?.subscription?.subscriptionFee || 0,
        jobPostAccess: activeSubscription?.subscription?.jobPosts || 0,
        userAccess: activeSubscription?.subscription?.userAccess || 0,
        cvAccess: activeSubscription?.subscription?.cvDownloads || 0,
        inviteAccess: activeSubscription?.subscription?.invites || 0,
        usedJobPostAccess: item.globalQuotas?.usedJobPostingAccess || 0,
        usedUserAccess: item.globalQuotas?.usedUserAccess || 0,
        usedInviteAccess: item.globalQuotas?.usedInviteAccess || 0,
        isVerified: item.isVerified,
        isPremium: activeSubscription?.subscription?.tier === "premium",
        remainingInvites: item.remainingInvites || 0,
        grossAmount: activeSubscription?.subscription?.subscriptionFee || 0,
        gst: 0.18 * (activeSubscription?.subscription?.subscriptionFee || 0),
        logo: item?.companyLogo?.url,
        validTill: formatDate(activeSubscription?.validUntil),
        subscribedOn: formatDate(activeSubscription?.subscribedOn),
      };
    });

    return result;
  } catch (error) {
    console.error("Error fetching Subscription details:", error);
    throw error;
  }
};

export const fetchJobSeekerSubscriptionsData = async () => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/jsWithSubs`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // Handle token invalidation or other issues
    if (!response) {
      return; // Handle logout and redirect behavior
    }

    // Map the response to match the required column fields
    return response?.users;
  } catch (error) {
    console.error("Error fetching Subscription details:", error);
    throw error;
  }
};
