import React from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Button, Box } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const EmployerListCardView = ({ companyData }) => {
  const navigate = useNavigate();

  const handleNavigate = (organizationId) => {
    navigate(`/employer/organizationTabs/${organizationId}`, {
      state: { organizationId },
    });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        height: "max-content", // Fixed height for consistent sizing
        flexDirection: "column",
        justifyContent: "space-between",
        bgcolor: "background.paper",
        boxShadow: "none",
        borderRadius: 2,
        padding: "1.5rem",
        textAlign: "left",
      }}
    >
      <Box>
        <Box
          sx={{ display: "flex", alignItems: "space-between", marginBottom: 2 }}
        >
          <img
            src={companyData?.companyLogo?.url}
            alt="Logo"
            style={{ width: 50, marginRight: 15 }}
          />
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontWeight: 600,
                color: "#6A1B9A",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {companyData.name || "Vacanzi"}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 600,
                color: "#a1a4b5",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {companyData.email || "Vacanzi"}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginBottom: 3 }}>
          <Typography
            variant="body1"
            sx={{
              marginBottom: 1,
              textAlign: "center",
              fontWeight: "bold",
              color: "#555",
            }}
          >
            {companyData.filename}
          </Typography>
          <video
            controls
            style={{
              width: "100%",
              height: "200px",
              borderRadius: "12px",
              border: "2px solid #ddd",
            }}
          >
            <source src={companyData.jobVideoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Typography
          variant="body1"
          sx={{
            color: "#51ce8a",
            marginBottom: 2,
            fontSize: "1.35rem",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 400,
          }}
        >
          {companyData.phone || "N/A"}
        </Typography>

        <Typography
          sx={{
            color: "#a1a4b5",
            marginBottom: 2,
            fontWeight: 400,
            lineHeight: 1.5,
            fontSize: "13px",
            fontFamily: "Poppins, sans-serif",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 3, // Limit to 3 lines
          }}
        >
          {companyData?.industry || "Job Role"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          onClick={() => handleNavigate(companyData.id)}
          sx={{
            width: "48%",
            fontSize: "12px",
            padding: "8px 0",
            background: "#51ce8a",
            "&:hover": { background: "#45b678" },
          }}
        >
          View More
        </Button>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <LocationOnIcon sx={{ color: "#777", fontSize: "16px" }} />
          <Typography
            variant="body2"
            sx={{
              color: "#777",
              fontSize: "12px",
              fontWeight: 400,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            {companyData.location || "Unknown Location"}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default EmployerListCardView;
