import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchOrganizationData = async () => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/organization/getallorg`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        // If the fetchWithAuth returns null due to token invalidation, handle accordingly
        if (!response) {
            return; // Handle logout and redirect behavior
        }

        // Map the response to match the required column fields
        const result = response.map((item, index) => ({
            siNo: index + 1,
            id: item._id,
            companyLogo:item.companyLogo,
            name: item.organizationName,
            email: item.email,
            phone: item.contactNumber,
            industry: item.industryType,
            jobVideoUrl:item.jobVideoUrl,
            size: item.jobPosts.length || 0, // Current postings vs limit
            location: item.organizationAddress,
            openings: item.jobPosts?.length || 0,
            requirements: [
                item.isPremium ? 'Premium' : 'Basic',
                item.isVerified ? 'Verified' : 'Unverified',
                item.isApproved ? 'Approved' : 'Pending Approval',
            ],
            marketingOrder: item?.marketingOrder
        }));

        return result;

    } catch (error) {
        console.error('Error fetching organization data:', error);
        throw error;
    }
};

export const fetchEmployerById = async (empId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error("No access token found");
    }

    try {
        // Make the API request using fetchWithAuth
        const response = await fetch(`${baseUrl}/organization/${empId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        }
    );
        // Handle the case where fetchWithAuth returns null (e.g., token invalidation)
        if (!response) {
            console.warn("Token may be invalid or session expired.");
            // Implement logout/redirect logic if needed
            return null;
        }

        // Check for HTTP errors
        if (!response.ok) {
            throw new Error("Failed to fetch Organization details");
        }

        // Parse and return JSON response
        return await response.json();
    } catch (error) {
        console.error("Error fetching organization details:", error);
        throw error; // Propagate error for further handling
    }
};

export const createOrganization = async (payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/organization/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // Set header for JSON
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        // Check if the response contains a success message
        if (response.message === 'Organization and recruiter-admin created successfully') {
            return response.organization; // Assuming response.organization contains the newly created organization
        } else {
            throw new Error(`Failed to add Organization: ${response.message || 'Unknown error'}`);
        }

    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate error to be handled elsewhere
    }
};

export const updateOrganization = async (orgId,payload) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/organization/${orgId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', // Set header for JSON
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(payload), // Convert payload to JSON string
        });

        if (!response) {
            throw new Error('Response was null or undefined');
        }

        // Check if the response contains a success message
        if (response.message === 'Organization updated successfully') {
            return response.organization; // Assuming response.organization contains the newly created organization
        } else {
            throw new Error(`Failed to update Organization: ${response.message || 'Unknown error'}`);
        }

    } catch (error) {
        console.error('Error:', error);
        throw error; // Propagate error to be handled elsewhere
    }
};

export const deleteOrganization = async (orgId) => {
    const token = localStorage.getItem('token')?.replace(/"/g, '');

    if (!token) {
        throw new Error('No access token found');
    }

    try {
        const response = await fetchWithAuth(`${baseUrl}/organization/${orgId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });
        // If the response is a success, return the message directly

        if (response.message === 'Organization marked as deleted') {

            return response; // Return success response
        } else {
            throw new Error(`Failed to delete role: ${response.message || 'Unknown error'}`);
        }
    } catch (error) {
        console.error('Error deleting role:', error);
        throw error;
    }
};


