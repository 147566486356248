import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Paper, Tab, Tabs } from '@mui/material';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import JobSeekerBio from '../../components/JobSeekers/JobSeekerBio';
import JobSeekerExpierence from '../../components/JobSeekers/JobSeekerExpierence';
import JobSeekerEducation from '../../components/JobSeekers/JobSeekerEducation';
import JobSeekerResume from '../../components/JobSeekers/JobSeekerResume';
import { fetchCandidateById } from '../../api/employer/jobseekers';
import CustomSnackbar from '../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../components/Tables/SkeletonTable';
import JobseekerDetails from './JobSeekerDetails';

const JobSeekerPersonalProfile = () => {
    const location = useLocation();
    const { id } = location?.state;
    const [data, setData] = useState();
    const [tabValue, setTabValue] = useState('one');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [loading, setLoading] = useState(true);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleErrorMessage = (error) => {
        const message = error.response?.message || "Failed to fetch data.";
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                const candidateData = await fetchCandidateById(id);
                setData(candidateData);
                setSnackbarSeverity("success");
            } catch (error) {
                handleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [id]);

    return (
        <AdminDrawerList>
            {loading ? (
                <SkeletonTable />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >
                    {/* Left Section: JobSeekerBio */}
                    <Box
                        sx={{
                            flexBasis: { xs: '100%', md: '30%' },
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <JobSeekerBio jobSeekerDetails={data}/>
                    </Box>

                    {/* Right Section: Tabs and Content */}
                    <Box
                        sx={{
                            flexBasis: { xs: '100%', md: '68%' },
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Paper
                            elevation={0}
                            sx={{
                                padding: 2,
                                borderRadius: '8px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {/* Tabs styled as buttons */}
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="white"
                                variant="fullWidth"
                                sx={{
                                    '& .MuiTab-root': {
                                        fontWeight: 600,
                                        textTransform: 'none',
                                        borderRadius: '8px',
                                        bgcolor: '#f5f5f5',
                                        mx: 0.5,
                                        '&.Mui-selected': {
                                            bgcolor: 'green',
                                            color: 'white'
                                        },
                                    },
                                }}
                            >
                                <Tab value="one" label="Experience" />
                                <Tab value="two" label="Education" />
                                <Tab value="three" label="Resume" />
                                <Tab value="four" label="Details" />
                            </Tabs>

                            {/* Tab Content */}
                            <Box sx={{ marginTop: 2 }}>
                                {tabValue === 'one' && <JobSeekerExpierence expierenceDetails={data?.experiences}  />}
                                {tabValue === 'two' && <JobSeekerEducation educationDetails={data?.educations} />}
                                {tabValue === 'three' && <JobSeekerResume jobSeekerDetails={data} />}
                                {tabValue === 'four' && <JobseekerDetails jobSeekerDetails={data} />}
                            </Box>
                        </Paper>
                    </Box>
                </Box>
            )}
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity} />
        </AdminDrawerList>
    );
};

export default JobSeekerPersonalProfile;
