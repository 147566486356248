import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawerList from "../../components/Drawer/AdminDrawerList";
import CustomSnackbar from "../../components/ResusableComponents/CustomSnackbar";
import {
  createJobSeeker,
  fetchJobSeekerData,
  uploadJobSeekers,
} from "../../api/employer/jobseekers";
import { fetchJobSeekerDataInternal } from "../../api/employer/jobseekers";
import SkeletonTable from "../../components/Tables/SkeletonTable";
import SeekersTable from "../../components/Tables/SeekersTable";
import { Typography, Paper, Box, Tabs, Tab } from "@mui/material";
import JobSeekerInternal from "./JobSeekerInernal";
import JobSeekersExternal from "./JobSeekerExternal";

const JobSeekers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeTab, setActiveTab] = useState("All"); // State to track the active tab
  const [dataInternal, setDataInternal] = useState([]);
  const [pagination, setPagination] = useState({});

  const navigate = useNavigate();

  const handleViewClick = (rowData) => {
    navigate(`/jobseeker/personalProfile/${rowData.id}`, { state: rowData });
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const OrganizationsData = await fetchJobSeekerData();
        setData(OrganizationsData);
        setSnackbarSeverity("success");
      } catch (error) {
        handleErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const fetchData = async (page = 1, pageSize = 1000) => {
    setLoading(true);
    try {
      const { result, pagination } = await fetchJobSeekerDataInternal(
        page,
        pageSize
      );
      setDataInternal(result);
      setPagination(pagination);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  const handlePageChange = (newPage) => {
    fetchData(newPage, pagination.pageSize || 10);
};
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleErrorMessage = (error) => {
    const message = error.response?.message || "Failed to fetch data.";
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleButtonClick = (tabName) => {
    setActiveTab(tabName);
  };

  const columns = [
    {
      field: "source",
      headerName: "Source",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 200,
      headerClassName: "data-grid-header",
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleViewClick(params.row)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "expectedCtc",
      headerName: "Expected CTC",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "designation",
      headerName: "Current Designation",
      minWidth: 240,
      headerClassName: "data-grid-header",
    },
    {
      field: "totalExpInYears",
      headerName: "Experience (Years)",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "currentLocation",
      headerName: "Current Location",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "preferredLocation",
      headerName: "Preferred Location",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "skills",
      headerName: "Skills",
      minWidth: 200,
      headerClassName: "data-grid-header",
      renderCell: (params) => <div>{params?.value?.join(", ")}</div>,
    },
  ];

  const handleSubmit = async (formData) => {
    // Prepare the body for the API request
    const body = {
      fullName: formData.fullName,
      email: formData.email,
      phone: formData.phone,
      gender: formData.gender,
      appliedPosition: formData.appliedPosition,
      preferredLocation: formData.preferredLocation,
      currentCompany: formData.currentCompany,
      currentDesignation: formData.currentDesignation,
      ctc: formData.ctc,
      noticePeriod: formData.noticePeriod,
      experience: formData.experience,
      experienceType: formData.experienceType,
      industry: formData.industry,
    };

    try {
      // Call the API with the updated body
      const result = await createJobSeeker(body);

      // Handle success or error based on `result`
      if (result) {
        setSnackbarMessage("JobSeeker Uploaded Successfully");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        // Handle API error
        setSnackbarMessage(
          result.data?.message || "Failed to save JobSeeker. Please try again."
        );
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error creating JobSeeker:", error);
      // Handle unexpected errors
      setSnackbarMessage("An unexpected error occurred. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file); // Attach file to the request

      const result = await uploadJobSeekers(formData, "invalidExcel"); // Call the helper function
      return result;
    } catch (error) {
      console.error("File upload failed:", error);
      throw error;
    }
  };

  return (
    <AdminDrawerList>
      <Box sx={{ display: "flex", mt: 2, mb: 5 }}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleButtonClick(newValue)}
          textColor="secondary"
          indicatorColor="white"
          sx={{
            "& .MuiTab-root": {
              fontWeight: 600,
              textTransform: "none",
              borderRadius: "8px",
              bgcolor: "#f5f5f5",
              px: 12, // Add padding inside the tabs for better aesthetics
              "&:not(:last-child)": {
                mr: 0, // Remove margin/gap between tabs
              },
              "&.Mui-selected": {
                bgcolor: "green",
                color: "white",
              },
            },
          }}
        >
          <Tab value="All" label="All" />
          <Tab value="Internal" label="Internal" />
          <Tab value="External" label="External" />
        </Tabs>
      </Box>

      {loading ? (
        <SkeletonTable />
      ) : (
        <>
          {activeTab === "All" && (
            <Paper
              elevation={0}
              sx={{
                mt: 20,
                margin: "40px 0",
                padding: "20px",
                backgroundColor: "white",
                flex: 1,
                height: "max-content",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography variant="h4" fontWeight="bolder">
                JobSeekers
              </Typography>
              <SeekersTable rows={data} columns={columns} />
            </Paper>
          )}
          {activeTab === "Internal" && (
            <Paper elevation={0} sx={{ padding: "20px" }}>
              <Typography variant="h4" fontWeight="bolder">
                Internal Job Seekers
              </Typography>
              <JobSeekerInternal
                rows={dataInternal}
                handleSubmit={handleSubmit}
                handleUpload={handleUpload}
                pagination={{
                    totalPages: pagination.totalPages || 1,
                    currentPage: pagination.currentPage || 1,
                }}
                onPageChange={handlePageChange}
              />
            </Paper>
          )}
          {activeTab === "External" && (
            <Paper elevation={0} sx={{ padding: "20px" }}>
              <Typography variant="h4" fontWeight="bolder">
                External Job Seekers
              </Typography>
              <JobSeekersExternal />
            </Paper>
          )}
          <CustomSnackbar
            open={snackbarOpen}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            severity={snackbarSeverity}
          />
        </>
      )}
    </AdminDrawerList>
  );
};

export default JobSeekers;
