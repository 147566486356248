import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled, Paper, TextField, Button, useMediaQuery, Typography } from '@mui/material';
import { jsonToCSV } from 'react-papaparse';
import debounce from 'lodash/debounce';

// Styled Components
const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const NoRowsMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const PromoCodesTable = ({ columns, rows }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const debouncedFilter = debounce((searchValue) => {
      if (!searchValue) {
        setFilteredRows(rows);
        return;
      }
      const lowercasedValue = searchValue.toLowerCase();
      const filteredData = rows.filter((row) =>
        columns.some((column) =>
          row[column.field]?.toString().toLowerCase().includes(lowercasedValue)
        )
      );
      setFilteredRows(filteredData);
    }, 300);
  
    debouncedFilter(searchText);
  
    return () => debouncedFilter.cancel();
  }, [searchText, rows, columns]);
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDownloadCSV = () => {
    try {
      const csvData = jsonToCSV(filteredRows);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'seekers_data.csv');
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating CSV:', error);
    }
  };

  return (
    <Paper elevation={0} sx={{ padding: '8px', maxWidth: '80vw' }}>
      <SearchContainer>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearch}
          sx={{ width: isSmallScreen ? '100%' : '300px' }}
        />
        <Button variant="contained" color="primary" onClick={handleDownloadCSV}>
          Export CSV
        </Button>
      </SearchContainer>
      {filteredRows?.length === 0 ? (
        <NoRowsMessage>No matching rows found.</NoRowsMessage>
      ) : (
        <DataGrid
          disableColumnResize={true}
          columnHeaderHeight={60}
          disableColumnSelector={true}
          disableColumnSorting={false}
          disableDensitySelector={true}
          disableRowSelectionOnClick={true}
          showCellVerticalBorder={true}
          rows={filteredRows}
          localeText={{
            columnMenuSortAsc: 'Sort A-Z',
            columnMenuSortDesc: 'Sort Z-A',
          }}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { page: 0, pageSize: 5 } },
          }}
          pageSizeOptions={[5, 10,25,50]}
          getRowHeight={() => 80}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              fontWeight: 'bold',
              fontSize: '14px',
              padding: '14px 6px',
              borderBottom: '2px solid #ccc',
            },
            '& .MuiDataGrid-cell': {
              color: '#a1a4b5',
              textOverflow: 'clip',
              whiteSpace: 'normal',
              lineHeight: '1.5',
              display: 'flex',
              alignItems: 'center',
              padding: '8px 16px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#f5f5f5',
              textAlign: 'center',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
              visibility: 'visible',
              width: '24px',
              height: '24px',
              marginLeft: '8px',
              display: 'inline-block',
              opacity: 1,
            },
            '& .MuiDataGrid-columnHeader:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          }}
        />
      )}
    </Paper>
  );
};

export default PromoCodesTable;
