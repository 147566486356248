import baseUrl from "../../config";
import fetchWithAuth from "../../utils/fetchWithAuth";

export const fetchAllJobPostings = async () => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/organization/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response) {
      throw new Error("Failed to fetch Organization details");
    }

    return response.jobs;
  } catch (error) {
    console.error("Error fetching organization details:", error);
    throw error;
  }
};

export const fetchAllIndustries = async () => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const response = await fetchWithAuth(`${baseUrl}/masters/Industry`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response) {
      throw new Error("Failed to fetch Industry details");
    }

    return response;
  } catch (error) {
    console.error("Error fetching Industry details:", error);
    throw error;
  }
};

export const fetchAllIndustriesById = async (industryType) => {
  const token = localStorage.getItem("token")?.replace(/"/g, "");

  if (!token) {
    throw new Error("No access token found");
  }

  try {
    const queryParam = industryType ? `?industry=${industryType}` : ""; // Add query param if jobType exists
    const response = await fetchWithAuth(`${baseUrl}/organization/all${queryParam}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response) {
      throw new Error("Failed to fetch Industry Type");
    }

    return response.jobs;
  } catch (error) {
    console.error("Error fetching Industry details:", error);
    throw error;
  }
};
