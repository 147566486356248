import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const CandidiateJobFilters = ({ jobDetails }) => {

    return (
        <Paper
            elevation={0} // Paper elevation for subtle background shadow
            sx={{
                backgroundColor: '#ffffff',
                padding: 2,
                borderRadius: 1,
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-evenly',
                alignItems: 'center',
                gap: 0,
                marginBottom: 3,
            }}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Application Count
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {jobDetails?.applicationCount}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Total View Count
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {jobDetails?.viewCount}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Total Saved Count
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {jobDetails?.savedCount}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper elevation={0} sx={{ padding: 2, textAlign: 'center', backgroundColor: '#e0ffff' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Total Shortlisted
                        </Typography>
                        <Typography variant="h5" fontWeight="bold">
                            {jobDetails?.shortListCount}
                        </Typography>
                    </Paper>
                </Grid>

            </Grid>
        </Paper>
    );
};

export default CandidiateJobFilters;
