import React from 'react';
import { useLocation } from 'react-router-dom';
import { Paper, Typography, Box } from '@mui/material';
import AdminDrawerList from '../../../components/Drawer/AdminDrawerList';

const JobSeekerSubscriptionDetails = () => {
    const location = useLocation();
    const allSubscription = location.state?.allSubscription;

    if (!allSubscription) {
        return <Typography>No subscription details available.</Typography>;
    }

    const {
        fullName,
        subscriptions,
    } = allSubscription;

    return (
        <AdminDrawerList>
            <Paper elevation={0} sx={{ padding: '2rem', borderRadius: '1rem', maxWidth: '1000px', margin: 'auto', bgcolor: 'background.paper' }}>
                <Typography variant="h5" sx={{ fontWeight: '600', color: '#6A1B9A', marginBottom: '1rem', fontFamily: 'Poppins, sans-serif' }}>
                    Subscription Details of : {fullName}
                </Typography>

                {subscriptions.map((plan, index) => (
                    <Box key={index} sx={{ marginBottom: '2rem' }}>
                        <Typography variant="body1" sx={{ fontWeight: 600, marginBottom: '0.5rem', fontFamily: 'Poppins, sans-serif' }}>
                            Tier: {plan.subscription?.tier}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            Subscribed  On: {new Date(plan.subscribedOn).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#999', marginBottom: '0.3rem', fontFamily: 'Poppins, sans-serif' }}>
                            subscriptionFee: {plan.subscription?.subscriptionFee}
                        </Typography>
                        
                    </Box>
                ))}
            </Paper>
        </AdminDrawerList>
    );
};

export default JobSeekerSubscriptionDetails;
