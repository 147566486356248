// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Container styling */
.employer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 16px;
}

/* Button group styling */
.button-group {
    display: flex;
    flex: 1 1;
    justify-content: flex-start;
    gap: 8px;
}

/* Action buttons */
.action-button {
    color: green;
    border: 1px solid green;
    padding: 8px 16px;
    text-transform: none;
    transition: all 0.3s ease;
}

.action-button:hover,
.action-button.active {
    background-color: green;
    color: white;
}

/* Post job button */
.post-job-button {
    background-color: #f2426d;
    color: white;
    padding: 8px 16px;
    text-transform: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.post-job-button:hover {
    background-color: #d93b60;
}

/* Dynamic content styling */
.dynamic-content {
    margin-top: 16px;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    border: 1px solid #ddd;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Employers/InformationTechnology/EmployerJobDetails/EmployerJobDetails.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,aAAa;IACb,SAAS;AACb;;AAEA,yBAAyB;AACzB;IACI,aAAa;IACb,SAAO;IACP,2BAA2B;IAC3B,QAAQ;AACZ;;AAEA,mBAAmB;AACnB;IACI,YAAY;IACZ,uBAAuB;IACvB,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;AAC7B;;AAEA;;IAEI,uBAAuB;IACvB,YAAY;AAChB;;AAEA,oBAAoB;AACpB;IACI,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;IACpB,YAAY;IACZ,eAAe;IACf,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,4BAA4B;AAC5B;IACI,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":["/* Container styling */\n.employer-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 16px;\n    gap: 16px;\n}\n\n/* Button group styling */\n.button-group {\n    display: flex;\n    flex: 1;\n    justify-content: flex-start;\n    gap: 8px;\n}\n\n/* Action buttons */\n.action-button {\n    color: green;\n    border: 1px solid green;\n    padding: 8px 16px;\n    text-transform: none;\n    transition: all 0.3s ease;\n}\n\n.action-button:hover,\n.action-button.active {\n    background-color: green;\n    color: white;\n}\n\n/* Post job button */\n.post-job-button {\n    background-color: #f2426d;\n    color: white;\n    padding: 8px 16px;\n    text-transform: none;\n    border: none;\n    cursor: pointer;\n    transition: all 0.3s ease;\n}\n\n.post-job-button:hover {\n    background-color: #d93b60;\n}\n\n/* Dynamic content styling */\n.dynamic-content {\n    margin-top: 16px;\n    padding: 16px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    border: 1px solid #ddd;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
