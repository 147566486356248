import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Paper, Tabs, Tab } from "@mui/material";
import SourcingTracker from "./SourcingTracker";
import UploadTracker from "../../components/Upload/UploadTracker";
import SeekersTable from "../../components/Tables/SeekersTable";
import baseUrl from "../../config";
import { Delete } from "@mui/icons-material";
import SeekersInternalTable from "../../components/Tables/SeekerInternalTable";
import Pagination from "@mui/material/Pagination";

const JobSeekerInternal = ({ handleSubmit, handleUpload }) => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(0);
  const [invalidJobseekers, setInvalidJobseekers] = useState([]);
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState({
    totalPages: 1,
    currentPage: 1,
    pageSize: 1000,
  });
  const [paginationInvalid, setPaginationInvalid] = useState({
    totalPages: 1,
    currentPage: 1,
    pageSize: 1000,
  });

  const fetchData = async (page = 1, pageSize = 1000) => {
    try {
      const response = await fetch(
        `https://admin.vacanzi.com/api/jobseekers?source=internal&page=${page}&pageSize=${pageSize}`
      );
      const data = await response.json();

      // Map response data to add unique IDs and required fields
      const result = data.data.map((item, index) => ({
        siNo: (page - 1) * pageSize + index + 1, // Unique serial number based on page
        id: item._id,
        source: item.source,
        fullName: item.fullName,
        email: item.email,
        phone: item.phone,
        gender: item.gender,
        appliedPosition: item.appliedPosition,
        preferredLocation: item.preferredLocation,
        expectedCtc: item.expectedCtc,
        currentCompany: item?.experiences?.[0]?.company,
        designation: item?.experiences?.[0]?.designation || "-",
        currentCtc: item?.experiences?.[0]?.ctc,
        experienceType: item.experienceType || "-",
        noticePeriod: item?.noticePeriod || 0,
        totalExpInYears: item.totalExpInYears || 0,
        currentLocation: item.currentLocation || "-",
        industry: item.industry || "-",
      }));

      setRows(result); // Update rows state with processed data
      setPagination(data.pagination); // Update pagination metadata
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (event, page) => {
    fetchData(page, pagination.pageSize); // Fetch data for the clicked page
  };

  useEffect(() => {
    fetchData(); // Initial data fetch
  }, []);
  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  // Fetch invalid jobseekers
  useEffect(() => {
    if (tabIndex === 1) {
      fetchDataInvalid(1); // Fetch the first page of invalid jobseekers initially
    }
  }, [tabIndex]);

  const fetchDataInvalid = (page = 1, pageSize = 10) => {
    const token = localStorage.getItem("token"); // Replace with how you manage your tokens

    fetch(`${baseUrl}/invalidJobseekers?page=${page}&pageSize=${pageSize}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Add the token here
      },
    })
      .then((response) => response.json())
      .then((data) => {

        // Transform the data to include an 'id' field from '_id'
        const transformedData = data.data.map((jobseeker) => ({
          ...jobseeker,
          id: jobseeker._id, // Ensure each jobseeker has a unique 'id'
        }));

        setInvalidJobseekers(transformedData); // Update state with transformed data
        setPaginationInvalid(data.pagination); // Update pagination metadata
      })
      .catch((error) =>
        console.error("Error fetching invalid jobseekers:", error)
      );
  };

  // Handle page change for invalid jobseekers
  const handleInvalidPageChange = (event, newPage) => {
    fetchDataInvalid(newPage, paginationInvalid.pageSize); // Fetch data for the clicked page
  };

  // Handle delete
  const handleDelete = (id) => {
    const token = localStorage.getItem("token");

    fetch(`${baseUrl}/deleteInvalidJobseekers`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        ids: [id], // Send the ID in the body if that's required by the API
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Remove the deleted jobseeker from the state
          setInvalidJobseekers((prevJobseekers) =>
            prevJobseekers.filter((jobseeker) => jobseeker.id !== id)
          );
          console.log("Jobseeker deleted successfully");
        } else {
          console.error("Error deleting jobseeker");
        }
      })
      .catch((error) => console.error("Error:", error));
  };

  const handleViewClick = (rowData) => {
    navigate(`/jobseeker/personalProfile/${rowData.id}`, { state: rowData });
  };

  const columns = [
    {
      field: "siNo",
      headerName: "SI No",
      minWidth: 60,
      headerClassName: "data-grid-header",
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleViewClick(params.row)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 200,
      headerClassName: "data-grid-header",
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleViewClick(params.row)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 280,
      headerClassName: "data-grid-header",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "gender",
      headerName: "Gender",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "appliedPosition",
      headerName: "Applied Position",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "preferredLocation",
      headerName: "Preferred Location",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "currentCompany",
      headerName: "Current Company",
      minWidth: 240,
      headerClassName: "data-grid-header",
    },
    {
      field: "designation",
      headerName: "Current Designation",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "totalExpInYears",
      headerName: "Experience (Years)",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "currentCtc",
      headerName: "Current CTC",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "noticePeriod",
      headerName: "Notice Period",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "experienceType",
      headerName: "Experience Type",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "industry",
      headerName: "Industry",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
  ];

  const invalidColumns = [
    { field: "fullName", headerName: "Name", minWidth: 200 },
    { field: "email", headerName: "Email", minWidth: 280 },
    { field: "phone", headerName: "Phone Number", minWidth: 200 },
    { field: "appliedPosition", headerName: "Applied Position", minWidth: 200 },
    {
      field: "preferredLocation",
      headerName: "Preferred Location",
      minWidth: 200,
    },
    {
      field: "validationErrors",
      headerName: "Validation Errors",
      minWidth: 400,
      renderCell: (params) => params.value.join(", "),
    },
    {
      field: "delete",
      headerName: "Actions",
      renderCell: (params) => (
        <Delete
          style={{ cursor: "pointer", color: "red" }}
          onClick={() => handleDelete(params.row._id)}
        />
      ),
      minWidth: 120,
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "stretch",
          padding: 3,
          gap: 2,
        }}
      >
        <Box sx={{ flex: 6 }}>
          <Paper sx={{ padding: 3 }} elevation={0}>
            <SourcingTracker handleSubmit={handleSubmit} />
          </Paper>
        </Box>
        <Box sx={{ flex: 6 }}>
          <Paper sx={{ padding: 3, height: "100%" }} elevation={0}>
            <UploadTracker handleUpload={handleUpload} />
          </Paper>
        </Box>
      </Box>

      <Box sx={{ marginTop: 3 }}>
        <Paper>
          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label="Valid Jobseekers" />
            <Tab label="Invalid Jobseekers" />
          </Tabs>
          <Box sx={{ padding: 3 }}>
            {tabIndex === 0 && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    shape="rounded"
                  />
                </Box>
                <SeekersInternalTable columns={columns} rows={rows} />
              </>
            )}
            {tabIndex === 1 && (
              <>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Pagination
                    count={paginationInvalid.totalPages}
                    page={paginationInvalid.currentPage}
                    onChange={handleInvalidPageChange}
                    color="primary"
                    shape="rounded"
                  />
                </Box>
                <SeekersTable
                  columns={invalidColumns}
                  rows={invalidJobseekers}
                  getRowId={(row) => row.id} // Ensure the grid gets the correct `id` field
                />
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default JobSeekerInternal;
