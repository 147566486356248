import React from "react";
import {
  Paper,
  Avatar,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import avatarmen from "../../assets/avatarmen.jpg";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import GoogleIcon from "@mui/icons-material/Google";

const JobSeekerBio = ({ jobSeekerDetails }) => {
  return (
    <Paper
      elevation={0}
      sx={{
        width: 430,
        padding: 2,
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: 2,
        }}
      >
        <Avatar
          src={jobSeekerDetails?.profileImage || avatarmen}
          alt="profile"
          sx={{ width: 84, height: 84 }}
        />
        <Typography sx={{ marginTop: 1 }} variant="h6">
          {jobSeekerDetails?.fullName}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {jobSeekerDetails?.experiences?.[0]?.designation}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Button variant="outlined">
          <Typography
            sx={{ textTransform: "none", fontWeight: "bold" }}
            variant="body2"
          >
            Message
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          flex: 0.5,
          textAlign: "center",
          backgroundColor: "silver", // Light background color
          borderRadius: "16px", // More rounded corners for a softer feel
          padding: 3, // Increase padding for better spacing
          marginRight: 2, // Slightly more spacing to separate from other components
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
          transition: "all 0.3s ease", // Smooth transition on hover
          "&:hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increased shadow on hover for interactivity
            transform: "scale(1.03)", // Slight scale effect on hover
          },
        }}
      >
        <Typography
          variant="body1"
          fontWeight={1000}
          fontSize="16px"
        >
          Wallet Balance
        </Typography>
        <Typography variant="h6" sx={{ color: "#1976d2", fontWeight: 700 }}>
          ₹{" "}
          {jobSeekerDetails?.walletId?.balance
            ? jobSeekerDetails?.walletId?.balance
            : 0}
        </Typography>
      </Box>

      <Box sx={{ paddingInline: 1, mt: 2 }}>
        <Typography variant="body1" fontWeight={1000} fontSize="13px">
          About me:
        </Typography>
        <Typography variant="caption" sx={{ color: "#a1a4b5" }}>
          {jobSeekerDetails?.description ||
            "Hi I am Nil Yeager,has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type."}
        </Typography>
        <Typography
          variant="body1"
          fontWeight={1000}
          fontSize="13px"
          sx={{ mt: 2 }}
        >
          Full Name:
          <Typography
            component="span"
            variant="caption"
            sx={{ color: "#a1a4b5", marginLeft: 1 }}
          >
            {jobSeekerDetails?.fullName}
          </Typography>
        </Typography>

        <Typography variant="body1" fontWeight={1000} fontSize="13px">
          Contact:
          <Typography
            component="span"
            variant="caption"
            sx={{ color: "#a1a4b5", marginLeft: 1 }}
          >
            {jobSeekerDetails?.phone}
          </Typography>
        </Typography>

        <Typography variant="body1" fontWeight={1000} fontSize="13px">
          Email:
          <Typography
            component="span"
            variant="caption"
            sx={{ color: "#a1a4b5", marginLeft: 1 }}
          >
            {jobSeekerDetails?.email}
          </Typography>
        </Typography>
        <Typography variant="body1" fontWeight={1000} fontSize="13px">
          DOB:
          <Typography
            component="span"
            variant="caption"
            sx={{ color: "#a1a4b5", marginLeft: 1 }}
          >
            {jobSeekerDetails?.dob
              ? new Intl.DateTimeFormat("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }).format(new Date(jobSeekerDetails.dob))
              : "N/A"}
          </Typography>
        </Typography>

        <Typography variant="body1" fontWeight={1000} fontSize="13px">
          Current Location:
          <Typography
            component="span"
            variant="caption"
            sx={{ color: "#a1a4b5", marginLeft: 1 }}
          >
            {jobSeekerDetails?.currentLocation}
          </Typography>
        </Typography>
       
      </Box>
      <Box display="flex" justifyContent="center" sx={{ gap: 2, mt: 2 }}>
        <IconButton sx={{ color: "#DB4437" }}>
          <GoogleIcon />
        </IconButton>
        <IconButton sx={{ color: "#1DA1F2" }}>
          <TwitterIcon />
        </IconButton>
        <IconButton sx={{ color: "#4267B2" }}>
          <FacebookIcon />
        </IconButton>
        <IconButton sx={{ color: "#C13584" }}>
          <InstagramIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 2,
          padding: 1,
        }}
      >
        <Box
          sx={{
            flex: 1,
            textAlign: "center",
            backgroundColor: "#e3f2fd",
            borderRadius: "8px",
            padding: 2,
            marginRight: 1,
          }}
        >
          <Typography variant="body1" fontWeight={1000} fontSize="13px">
            Profile Views
          </Typography>
          <Typography variant="h6" sx={{ color: "#1976d2" }}>
            {jobSeekerDetails?.profileViewCount || 0}
          </Typography>
        </Box>

        <Box
          sx={{
            flex: 1,
            textAlign: "center",
            backgroundColor: "#ede7f6",
            borderRadius: "8px",
            padding: 2,
            marginLeft: 1,
          }}
        >
          <Typography variant="body1" fontWeight={1000} fontSize="13px">
            HR Contacted
          </Typography>
          <Typography variant="h6" sx={{ color: "#512da8" }}>
            {jobSeekerDetails?.hrContactedCount || 0}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export default JobSeekerBio;
