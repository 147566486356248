import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomSnackbar from '../../components/ResusableComponents/CustomSnackbar';
import { fetchJobSeekerDataExternal } from '../../api/employer/jobseekers';
import SkeletonTable from '../../components/Tables/SkeletonTable';
import SeekersTable from '../../components/Tables/SeekersTable';
import { Paper } from '@mui/material';

const JobSeekersExternal = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const navigate = useNavigate();


    const handleViewClick = (rowData) => {
        navigate(`/jobseeker/personalProfile/${rowData.id}`, { state: rowData });
    }
    useEffect(() => {
        const getData = async () => {
            try {
                const ExternalJobSeekersData = await fetchJobSeekerDataExternal();
                setData(ExternalJobSeekersData);
                setSnackbarSeverity("success");
            } catch (error) {
                handleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);

    const handleSnackbarClose = () => setSnackbarOpen(false);

    const handleErrorMessage = (error) => {
        const message = error.response?.message || "Failed to fetch data.";
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const columns = [
        {
            field: 'fullName', headerName: 'Name', minWidth: 200, headerClassName: 'data-grid-header', renderCell: (params) => (
                <div style={{ cursor: 'pointer' }} onClick={() => handleViewClick(params.row)}>
                    {params.value}
                </div>
            ),
        },
        { field: 'email', headerName: 'Email', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'phone', headerName: 'Phone Number', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'expectedCtc', headerName: 'Expected CTC', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'designation', headerName: 'Current Designation', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'totalExpInYears', headerName: 'Experience (Years)', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'currentLocation', headerName: 'Current Location', minWidth: 200, headerClassName: 'data-grid-header' },
        { field: 'preferredLocation', headerName: 'Preffered Location', minWidth: 200, headerClassName: 'data-grid-header' },
        {
            field: 'skills',
            headerName: 'Skills',
            minWidth: 200,
            headerClassName: 'data-grid-header',
            renderCell: (params) => (
                <div>
                    {params?.value?.join(', ')}
                </div>
            ),
        },
    ];

    return (
        <>
            {loading ? (
                <SkeletonTable />
            ) : (
                <>
                    <Paper
                        elevation={0}
                        sx={{
                            margin: "40px 0",
                            padding: "20px",
                            backgroundColor: "white",
                            flex: 1,
                            height: 'max-content',
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >

                        <SeekersTable rows={data} columns={columns} />
                    </Paper>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={handleSnackbarClose}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                </>
            )}
        </>
    );
};

export default JobSeekersExternal;
