import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminDrawerList from "../../../components/Drawer/AdminDrawerList";
import {
  Typography,
  Button,
  Tooltip,
  IconButton,
  styled,
  Paper,
  Grid,
  Tabs,
  Tab,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import SeekersTable from "../../../components/Tables/SeekersTable";
import {
  fetchOrganizationData,
  deleteOrganization,
} from "../../../api/employer/employerlist";
import CustomSnackbar from "../../../components/ResusableComponents/CustomSnackbar";
import ConfirmDialog from "../../../components/ResusableComponents/ConfirmDialog";
import SkeletonTable from "../../../components/Tables/SkeletonTable";
import EmployerListCardView from "./EmployerListCardView";
import baseUrl from "../../../config";

const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
}));

const EmployerList = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("List View");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [tempOrder, setTempOrder] = useState({});

  useEffect(() => {
    const getData = async () => {
      try {
        const OrganizationsData = await fetchOrganizationData();
        setData(OrganizationsData);
      } catch (error) {
        handleErrorMessage(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleButtonClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleAddStaffClick = () => {
    navigate("/employer/addEmployer");
  };

  const handleEditClick = (rowData) => {
    navigate(`/employer/editEmployer/${rowData.id}`, { state: rowData });
  };

  const handleViewClick = (rowData) => {
    navigate(`/employer/organizationTabs/${rowData.id}`, { state: rowData });
  };

  const handleDeleteClick = (rowData) => {
    setSelectedRow(rowData);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    if (selectedRow) {
      try {
        await deleteOrganization(selectedRow.id);
        setData(data.filter((row) => row._id !== selectedRow.id));
        setSnackbarMessage("Organization deleted successfully");
      } catch (error) {
        const errorMessage =
          error?.response?.data?.message ||
          error?.message ||
          "Error deleting Organization. Please try again.";
        setSnackbarOpen(true);
        setOpenDialog(false);
        setSnackbarMessage(errorMessage);
        handleErrorMessage(error);
      } finally {
        setSnackbarOpen(true);
        setOpenDialog(false);
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleErrorMessage = (error) => {
    const message = error.response?.message;
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleMarketingOrderChangeInput = (id, newOrder) => {
    setTempOrder((prevState) => ({
      ...prevState,
      [id]: newOrder,
    }));
  };

  const handleMarketingOrderChange = async (id, newOrder) => {
    try {
        const token = localStorage.getItem('token')
      // Call the API to update the marketing order
      const response = await fetch(`${baseUrl}/organization/updateMarketingOrder/${id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ marketingOrder: newOrder }),
      });
  
      // Check if the response is successful (status code 200)
      if (response.ok) {
        // Update the local state
        setData((prevData) =>
          prevData.map((row) =>
            row.id === id ? { ...row, marketingOrder: newOrder } : row
          )
        );
  
        // Show the snackbar with success message
        setSnackbarMessage("Marketing order updated successfully");
        setSnackbarOpen(true);
      } else {
        // Handle the error if the response is not successful
        setSnackbarMessage("Failed to update marketing order");
        setSnackbarOpen(true);
      }
    } catch (error) {
      // Handle network errors or other issues
      setSnackbarMessage("Failed to update marketing order");
      setSnackbarOpen(true);
      console.error(error);
    }
  };
  

  const columns = [
    { field: "siNo", headerName: "ID", minWidth: 60, sortable: true },
    {
      field: "name",
      headerName: "Company Name",
      minWidth: 240,
      headerClassName: "data-grid-header",
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleViewClick(params.row)}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "industry",
      headerName: "Industry",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "size",
      headerName: "Job Posting",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "openings",
      headerName: "Openings",
      minWidth: 200,
      headerClassName: "data-grid-header",
    },
    {
      field: "marketingOrder",
      headerName: "Marketing Order",
      minWidth: 200,
      headerClassName: "data-grid-header",
      renderCell: (params) => {
        const currentOrder =
          tempOrder[params.row.id] || params.row.marketingOrder;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="number"
              value={currentOrder || ""}
              onChange={(e) =>
                handleMarketingOrderChangeInput(params.row.id, e.target.value)
              }
              style={{
                width: "100%",
                padding: "4px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                textAlign: "center",
              }}
            />
            <button
              onClick={() =>
                handleMarketingOrderChange(params.row.id, currentOrder)
              }
              style={{
                padding: "4px 8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
                backgroundColor: "#4CAF50",
                color: "white",
                marginLeft: "8px",
                cursor: "pointer",
              }}
            >
              Update
            </button>
          </div>
        );
      },
    },

    {
      field: "requirements",
      headerName: "Requirements",
      minWidth: 200,
      headerClassName: "data-grid-header",
      renderCell: (params) => <div>{params.value?.join(", ")}</div>,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip title="Edit">
            <IconButton
              color="default"
              onClick={() => handleViewClick(params.row)}
            >
              <VisibilityIcon sx={{ mr: "10px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="success"
              onClick={() => handleEditClick(params.row)}
            >
              <EditIcon sx={{ mr: "10px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              color="error"
              onClick={() => handleDeleteClick(params.row)}
            >
              <DeleteIcon sx={{ mr: "10px" }} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <AdminDrawerList>
      <Box sx={{ display: "flex", mt: 2 }}>
        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleButtonClick(newValue)}
          textColor="secondary"
          indicatorColor="white"
          sx={{
            "& .MuiTab-root": {
              fontWeight: 600,
              textTransform: "none",
              borderRadius: "8px",
              bgcolor: "#f5f5f5",
              px: 12, // Add padding inside the tabs for better aesthetics
              "&:not(:last-child)": {
                mr: 0, // Remove margin/gap between tabs
              },
              "&.Mui-selected": {
                bgcolor: "green",
                color: "white",
              },
            },
          }}
        >
          <Tab value="List View" label="List View" />
          <Tab value="Card View" label="Card View" />
        </Tabs>
      </Box>

      {activeTab === "List View" ? (
        <>
          <Paper
            elevation={0}
            sx={{
              margin: "40px 0",
              padding: "20px",
              flex: 1,
              height: "max-content",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h4" fontWeight="bolder">
              Employers
            </Typography>
            <ButtonContainer>
              <Button
                variant="contained"
                color="success"
                startIcon={<AddIcon />}
                onClick={handleAddStaffClick}
              >
                Add Employers
              </Button>
            </ButtonContainer>
            {loading ? (
              <SkeletonTable />
            ) : (
              <SeekersTable rows={data} columns={columns} disableRowSelected />
            )}
          </Paper>
        </>
      ) : (
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {data.map((companyData, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <EmployerListCardView companyData={companyData} />
            </Grid>
          ))}
        </Grid>
      )}
      <ConfirmDialog
        open={openDialog}
        onClose={handleCloseDialog}
        onConfirm={confirmDelete}
        title="Confirm Deletion"
        content="Are you sure you want to delete this Organization?"
      />
      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={
          snackbarMessage?.includes("successfully") ? "success" : "error"
        }
      />
    </AdminDrawerList>
  );
};

export default EmployerList;
