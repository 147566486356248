import { logout } from "../hooks/useLogout";

const fetchWithAuth = async (url, options = {}) => {
    const token = localStorage.getItem('token');

    const headers = {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : undefined,
        ...options.headers,
    };

    const config = {
        ...options,
        headers,
    };

    try {
        const response = await fetch(url, config);

        // Handle 401 Unauthorized (Token Invalid)
        if (response.status === 401) {
            const errorData = await response.json().catch(() => ({})); // Handle empty body
            if (errorData.message === 'Token has been invalidated') {
                logout(); // Clear token and logout
                return; // Prevent further execution
            }
        }

        // Handle non-200 responses with detailed error
        if (!response.ok) {
            const errorData = await response.json().catch(() => ({})); // Fallback to empty object
            const errorMessage = errorData.message || `HTTP Error: ${response.status}`;
            throw new Error(errorMessage);
        }

        // Parse JSON response only if content exists
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        }

        // Return response directly for non-JSON (e.g., empty body or plain text)
        return response;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error; // Rethrow to handle in the calling function
    }
};

export default fetchWithAuth;
