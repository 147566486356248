import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const UploadTracker = ({ handleUpload }) => {
  const [uploadStatus, setUploadStatus] = useState(null); // For status messages
  const [isUploading, setIsUploading] = useState(false); // To track upload progress

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return; // No file selected
    }

    // Check if the uploaded file is .xlsx
    const validMimeTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // For .xlsx files
    ];

    if (!validMimeTypes.includes(file.type)) {
      setUploadStatus('Unsupported file type. Please upload a .xlsx file.');
      return;
    }

    // Start uploading
    setIsUploading(true);
    setUploadStatus(null); // Clear any previous status message

    try {
      // Call the parent-provided `handleUpload` function
      await handleUpload(file);

      // Set success status once the upload is completed
      setUploadStatus('File uploaded successfully.');
    } catch (error) {
      console.error('File upload failed:', error);
      setUploadStatus('File upload failed. Please try again.');
    } finally {
      setIsUploading(false); // End uploading state
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%', // Full height of parent container
        textAlign: 'center', // Center align text
        padding: 3,
      }}
    >
      {/* Title */}
      <Typography variant="h6" gutterBottom>
        Upload your Resource Tracker here 👇
      </Typography>

      {/* Instructions */}
      <Typography variant="body2" sx={{ marginBottom: 2, color: 'gray' }}>
        Click or drag file to this area to upload
      </Typography>
      <Typography variant="body2" sx={{ marginBottom: 2, color: 'gray' }}>
        Upload one file at a time.
      </Typography>

      {/* Upload Button */}
      <Box
        sx={{
          border: '1px dashed #ccc',
          borderRadius: 2,
          padding: 2,
          textAlign: 'center',
          width: '100%',
          maxWidth: 400, // Optional max width
        }}
      >
        <Button
          variant="contained"
          component="label"
          startIcon={<UploadFileIcon />}
          fullWidth
        >
          Upload .xlsx
          <input
            type="file"
            hidden
            accept=".xlsx"
            onChange={handleFileUpload}
          />
        </Button>
        <Typography variant="body2" sx={{ marginTop: 1, color: 'gray' }}>
          Supported file type: .xlsx
        </Typography>
      </Box>

      {/* Status Message */}
      {isUploading ? (
        <Typography
          variant="body2"
          sx={{
            marginTop: 2,
            color: 'blue', // Blue for waiting message
          }}
        >
          Please wait until we get a response...
        </Typography>
      ) : (
        uploadStatus && (
          <Typography
            variant="body2"
            sx={{
              marginTop: 2,
              color: uploadStatus.includes('successfully') ? 'green' : 'red',
            }}
          >
            {uploadStatus}
          </Typography>
        )
      )}
    </Box>
  );
};

export default UploadTracker;
