import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import JobOverview from '../../../../../components/EmployerJobDetailsComponents/JobDetails/JobOverView';
import JobDetailsCard from '../../../../../components/EmployerJobDetailsComponents/JobDetails/JobDetaillsCard';
import JobVideoCards from '../../../../../components/EmployerJobDetailsComponents/JobDetails/JobVideoCards';
import { fetchEmployerJobPostedDetailsById, fetchEmployerJobsPostedById } from '../../../../../api/employer/employerjobs';
import CustomSnackbar from '../../../../../components/ResusableComponents/CustomSnackbar';
import SkeletonTable from '../../../../../components/Tables/SkeletonTable';

const EmployerJobStats = ({ jobId, organizationId }) => {
  const [data, setData] = useState(null);
  const [jobDetails, setJobDetails] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(true);

  const handleErrorMessage = (error) => {
    const message = error.response?.message || "Failed to fetch data.";
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const organizationData = await fetchEmployerJobsPostedById(organizationId);
        setData(organizationData);
        setSnackbarSeverity("success");
      } catch (error) {
        handleErrorMessage(error);
        setSnackbarSeverity("error");
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [organizationId]);

  useEffect(() => {
    const getData = async () => {
      try {
        const jobData = await fetchEmployerJobPostedDetailsById(jobId);
        setJobDetails(jobData);
        setSnackbarSeverity("success");
      } catch (error) {
        handleErrorMessage(error);
        setSnackbarSeverity("error");
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [jobId]);

  return (
    <>
      <div>
        {loading ? (
          <SkeletonTable />
        ) : (
          <><Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2, // Add spacing between components
            margin: 2 // Optional margin for better overall spacing
          }}>
            <JobVideoCards videodata={data} />
          </Box><Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2, // Add spacing between components
              margin: 2 // Optional margin for better overall spacing
            }}
          >

              {/* JobOverview takes 60% of the width */}
              <Box sx={{ flex: 10 }}>
                <JobOverview jobDetails={jobDetails} />
              </Box>

              {/* JobDetailsCard takes 40% of the width */}
              <Box sx={{ flex: 4 }}>
                <JobDetailsCard jobDetails={jobDetails} />
              </Box>
            </Box><CustomSnackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
              severity={snackbarSeverity} /></>
        )}</div></>
  );
}

export default EmployerJobStats;
