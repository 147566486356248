import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminDrawerList from '../../components/Drawer/AdminDrawerList';
import { Typography, Button, Tooltip, IconButton, styled, Skeleton, Grid, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LockResetIcon from '@mui/icons-material/LockReset';
import AddIcon from '@mui/icons-material/Add';
import DataTable from '../../components/Tables/DataTables';
import { fetchRolesData, deleteRole, resetPassword } from '../../api/userRoles/roles';
import CustomSnackbar from '../../components/ResusableComponents/CustomSnackbar';
import ConfirmDialog from '../../components/ResusableComponents/ConfirmDialog';
import ResetPasswordDialog from '../../components/ResusableComponents/ResetPasswordDialog';



const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2), // Reduced spacing for better fit
}));

const SkeletonTable = () => (
    <Grid container spacing={2}>
        {[...Array(5)].map((_, index) => (
            <React.Fragment key={index}>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
                <Grid item xs={2}>
                    <Skeleton variant="rectangular" height={40} />
                </Grid>
            </React.Fragment>
        ))}
    </Grid>
);

const Roles = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openResetDialog, setOpenResetDialog] = useState(false);


    useEffect(() => {
        const getData = async () => {
            try {
                const rolesData = await fetchRolesData();
                setData(rolesData);
            } catch (error) {
                handleErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);


    const handleAddStaffClick = () => {
        navigate('/add-roles');
    };

    const handleEditClick = (rowData) => {
        navigate(`/edit-roles/${rowData._id}`, { state: rowData });
    };

    const handleDeleteClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const confirmDelete = async () => {
        if (selectedRow) {
            try {
                // Call deleteRole to delete the selected role
                await deleteRole(selectedRow._id);

                // Only remove from data state if the response is successful
                setData(data.filter((row) => row._id !== selectedRow._id));

                // Show success message
                setSnackbarMessage('Role deleted successfully');
            } catch (error) {
                // Handle any errors that occur during deletion
                const errorMessage =
                    error?.response?.data?.message ||
                    error?.message ||
                    "Error adding role. Please try again.";

                // Display the error message in the Snackbar
                setSnackbarOpen(true);
                setOpenDialog(false);
                setSnackbarMessage(errorMessage)
                handleErrorMessage(error);
            } finally {
                // Ensure the snackbar is shown and the dialog is closed
                setSnackbarOpen(true);
                setOpenDialog(false);
            }
        }
    };


    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleResetPasswordClick = (rowData) => {
        setSelectedRow(rowData);
        setOpenResetDialog(true);
    };

    const handleConfirmResetPassword = async (newPassword, secretKey) => {
        if (selectedRow) {
            try {
                // Call resetPassword with adminId, newPassword, and secretKey
                await resetPassword({
                    adminId: selectedRow._id,
                    newPassword,
                    secretKey: secretKey || undefined, // Pass secretKey if available
                });

                // Set the snackbar message for success
                setSnackbarMessage('Password reset successfully');
            } catch (error) {
                // Handle any errors that occur during the reset
                handleErrorMessage(error);
            } finally {
                // Ensure the snackbar is shown and the dialog is closed
                setSnackbarOpen(true);
                setOpenResetDialog(false);
            }
        }
    };



    const handleCloseResetDialog = () => {
        setOpenResetDialog(false);
    };

    const handleErrorMessage = (error) => {
        const message = error.response?.message;
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const columns = [
        { field: 'siNo', headerName: 'ID', minWidth: 60, sortable: true },
        { field: 'id', headerName: 'User ID', minWidth: 240, sortable: true },
        { field: 'name', headerName: 'Name', minWidth: 200, sortable: true },
        { field: 'email', headerName: 'Email', minWidth: 260, sortable: true },
        { field: 'phoneNumber', headerName: 'Phone Number', minWidth: 200, sortable: true },
        { field: 'role', headerName: 'Role', minWidth: 180, sortable: true },
        { field: 'createdAt', headerName: 'Register Date', minWidth: 260, sortable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 200,
            sortable: false,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Tooltip title="Reset Password">
                        <IconButton color="primary" onClick={() => handleResetPasswordClick(params.row)}>
                            <LockResetIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit">
                        <IconButton color="success" onClick={() => handleEditClick(params.row)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton color="error" onClick={() => handleDeleteClick(params.row)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <AdminDrawerList>
            <Paper
                elevation={0}
                sx={{
                    margin: "0 auto",
                    padding: "20px",
                    backgroundColor: "white",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                }}
            >
                <Typography variant="h4" fontWeight="bolder">Roles</Typography>


                <ButtonContainer>
                    <Button
                        variant="contained"
                        color="success"
                        startIcon={<AddIcon />}
                        onClick={handleAddStaffClick}
                    >
                        Add Roles
                    </Button>
                </ButtonContainer>
                {loading ? (
                    <SkeletonTable />
                ) : (
                    <DataTable
                        rows={data}
                        columns={columns}
                        disableRowSelected
                    />

                )}
                <ConfirmDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    onConfirm={confirmDelete}
                    title="Confirm Deletion"
                    content="Are you sure you want to delete this role?"
                />

                <ResetPasswordDialog
                    open={openResetDialog}
                    onClose={handleCloseResetDialog}
                    onConfirm={handleConfirmResetPassword}
                    title={`Reset Password for ${selectedRow?.name}`}
                    role={selectedRow?.role}
                    email={selectedRow?.email}
                />

                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={handleSnackbarClose}
                    message={snackbarMessage}
                    severity={snackbarMessage?.includes('successfully') ? 'success' : 'error'}
                />
            </Paper>
        </AdminDrawerList>
    );
};

export default Roles;
