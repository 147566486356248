import React, { useState } from 'react';
import {
  Paper,
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Avatar,
  InputAdornment,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import avatarmen from '../../assets/avatarmen.jpg';

const ChatList = ({ onChatClick, users }) => {
  // State for the search input
  const [searchTerm, setSearchTerm] = useState('');

  // Filtered users based on the search term
  const filteredUsers = users.filter((user) => {
    const organizationName = user?.organization?.organizationName || '';
    const recruiterName = user?.recruiter?.rcName || '';
    const lastMessage = user?.messages?.length
      ? user.messages[user.messages.length - 1].messageContent
      : '';

    // Check if the search term matches any of the fields
    const searchTarget = `${organizationName} ${recruiterName} ${lastMessage}`.toLowerCase();
    return searchTarget.includes(searchTerm.toLowerCase());
  });

  return (
    <Paper
      elevation={0}
      sx={{
        maxWidth: 430,
        padding: 2,
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      {/* Search Bar */}
      <TextField
        placeholder="Search..."
        variant="standard"
        size="small"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Update search term
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          disableUnderline: true, // Removes the underline
        }}
        sx={{
          marginBottom: 2,
          backgroundColor: 'transparent',
          border: '1px solid #ccc',
          borderRadius: '14px',
          padding: '4px 8px',
        }}
      />

      {/* User List */}
      <Box
        sx={{
          overflowY: 'auto',
          height: '100%',
        }}
      >
        <List>
          {filteredUsers.map((user) => {
            const companyLogo = user?.organization?.companyLogo?.url
            const organizationName = user?.organization?.organizationName || 'Unknown Organization';
            const recruiterName = user?.recruiter?.rcName || 'Unknown Recruiter';
            const lastMessage = user?.messages?.length
              ? user.messages[user.messages.length - 1].messageContent
              : 'No messages yet';

            return (
              <ListItem
                key={user._id}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f5f5f5' },
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
                onClick={() => onChatClick(user)} // Pass selected user to the parent
              >
                {/* Avatar */}
                <Avatar
                  sx={{ marginRight: 2, marginTop: '4px' }}
                  src={companyLogo || avatarmen}
                >
                  {recruiterName.charAt(0)}
                </Avatar>

                {/* Text Details */}
                <ListItemText
                  primary={
                    <Box>
                      {/* Recruiter Name */}
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontSize: '14px',
                          fontWeight: 600,
                          color: 'text.primary',
                        }}
                      >
                        {organizationName}
                      </Typography>

                      {/* Organization Name */}
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontSize: '12px',
                          color: 'text.secondary',
                          fontWeight: 'bold',
                        }}
                      >
                        {recruiterName}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: 'text.secondary',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {lastMessage}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Paper>
  );
};

export default ChatList;
