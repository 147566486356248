import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Box,
  Badge,
  Avatar,
  Typography,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import Brightness4Icon from '@mui/icons-material/Brightness4';
// import Brightness7Icon from '@mui/icons-material/Brightness7';
import AvatarMen from "../../assets/avatarmen.jpg";

const NavBar = ({ isDrawerOpen, handleMenuClick, toggleTheme, isDarkMode }) => {
  const [userData, setUserData] = useState(null);
  const [notifications] = useState(3); // Dummy notifications count
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    const userResponse = localStorage.getItem("userResponse");
    if (userResponse) {
      setUserData(JSON.parse(userResponse));
    }
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleMenuClose();
    localStorage.clear();
    navigate("/login");
  };

  const handleProfileClick = () => {
    navigate('/adminProfile'); // Assuming '/profile' is the route for the profile page
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: `calc(100% - ${isDrawerOpen ? 240 : 64}px)`,
        ml: isDrawerOpen ? `240px` : `64px`,
        bgcolor: "background.paper",
        boxShadow: "none",
      }}
    >
      <Menu anchorEl={anchorEl} open={openMenu} onClose={handleMenuClose}>
        <MenuItem onClick={handleProfileClick}>
          <Avatar src={AvatarMen} alt="User Avatar" />
          <Typography variant="body1" sx={{ marginLeft: 2 }}>
            {userData?.fullName}
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography variant="body2" sx={{ marginLeft: 2 }}>
            Role: {userData?.role || "Admin"}
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogoutClick}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </MenuItem>
      </Menu>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Drawer Toggle */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            disabled
            onClick={handleMenuClick}
            sx={{
              mr: 2,
              color: "text.primary",
              "&:hover": { bgcolor: "action.hover" },
            }}
          >
            {/* <MenuIcon /> */}
          </IconButton>

          {/* Search Bar */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "300px",
              bgcolor: "#e0ffff",
              borderRadius: 1,
              px: 2,
              p: 0.8,
            }}
          >
            <InputBase
              sx={{
                flex: 1,
                color: "black",
              }}
              placeholder="Search..."
              inputProps={{ "aria-label": "search" }}
            />
            <SearchIcon sx={{ color: "black" }} />
          </Box>
        </Box>

        {/* Right Section */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Dark Mode Toggle */}
          {/* <IconButton onClick={toggleTheme} sx={{ mx: 1 }}>
            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton> */}

          {/* Notifications */}
          <IconButton sx={{ mx: 1 }}>
            <Badge badgeContent={notifications} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {/* Avatar */}
          <IconButton sx={{ mx: 1 }} onClick={handleMenuOpen}>
            <Avatar alt="User Avatar" src={AvatarMen} />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
