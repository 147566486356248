import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled, Paper, TextField, useMediaQuery, IconButton, Typography, Button } from '@mui/material';
import { jsonToCSV } from 'react-papaparse';
import debounce from 'lodash/debounce';


const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: theme.spacing(1),
}));

const NoRowsMessage = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

// Debounced filtering function
const debouncedFilterRows = debounce((searchValue, rows, columns, setFilteredRows) => {
  if (searchValue === '') {
    setFilteredRows(rows);
  } else {
    const value = searchValue?.toLowerCase();
    const filteredData = rows.filter((row) =>
      columns.some((column) =>
        row[column.field]?.toString().toLowerCase().includes(value)
      )
    );
    setFilteredRows(filteredData);
  }
}, 300);

const DataTable = ({ rows, columns }) => {
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState(rows);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const filterRows = useCallback(
    (searchValue) => {
      debouncedFilterRows(searchValue, rows, columns, setFilteredRows);
    },
    [rows, columns]
  );

  useEffect(() => {
    filterRows(searchText);
  }, [searchText, filterRows]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleDownloadCSV = () => {
    try {
      const csvData = jsonToCSV(filteredRows);
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.setAttribute('download', 'data.csv');
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating CSV:', error);
    }
  };

  return (
    <Paper elevation={0} sx={{ padding:'8px'}}>
      <SearchContainer>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearch}
          sx={{
            width: isSmallScreen ? '100%' : '300px',
            marginBottom: isSmallScreen ? '8px' : '0',
          }}
        />
        <IconButton onClick={handleDownloadCSV} color="primary">
          <Button variant='contained'>Export</Button>
        </IconButton>
      </SearchContainer>
      {filteredRows?.length === 0 ? (
        <NoRowsMessage>No matching rows found.</NoRowsMessage>
      ) : (
        <Paper elevation={0} sx={{ height: '60vh', maxWidth: '80vw', }}>
          <DataGrid
            disableColumnResize={true}
            columnHeaderHeight={60}
            disableColumnSelector={true}
            disableColumnSorting={false}
            disableDensitySelector={true}
            disableRowSelectionOnClick={true}
            showCellVerticalBorder={true}
            rows={filteredRows}
            localeText={{
              columnMenuSortAsc: 'Sort A-Z', // Customize ascending sorting label
              columnMenuSortDesc: 'Sort Z-A', // Customize descending sorting label
            }}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            getRowHeight={() => 90}
            style={{ height: '100%', width: '100%' ,}}
            sx={{
              '& .MuiDataGrid-columnHeader': {
                fontWeight: 'bold', // Make header text bold
                fontSize: '14px',   // Adjust font size
                padding: '14px 6px', // Add padding to the header cells
                borderBottom: '2px solid #ccc'
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center'
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-iconButtonContainer': {
                visibility: 'visible',  // Ensure the filter icon is always visible
                width: 'auto',
                marginRight: '8px',
              },
              '& .MuiDataGrid-columnHeader .MuiDataGrid-menuIcon': {
                visibility: 'visible', // Ensure the filter icon is always visible
                width: '24px',         // Fixed width for the icon to prevent layout shift
                height: '24px',        // Fixed height for consistency
                marginLeft: '8px',     // Adjust space between the header title and the icon
                display: 'inline-block', // Display as inline block to avoid breaking layout
                opacity: 1,            // Ensure the icon is fully visible
                transition: 'none',     // Remove transition to prevent movement
              },
              '& .MuiDataGrid-columnHeader:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)', // Optional: Add hover effect without affecting layout
              }
            }}
          />
        </Paper>
      )}
    </Paper>
  );
};

export default DataTable;
